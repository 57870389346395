import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Alert,
  Platform,
  BackHandler,
} from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useRoute, RouteProp, useNavigation, useFocusEffect } from '@react-navigation/native';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import validerDemande from '../validerFormulaire';
import DocumentOrPhotoPicker from '../components/DocumentOrPhotoPicker';

type FormScreenRouteParams = {
  title: string;
  prixconsulat: number;
  prixintervention: number;
  demandeId: number;
  url_flag: string;
  validite: string;
  informations: string;
};

type RootStackParamList = {
  FormSeychelles: FormScreenRouteParams;
  Demandes: {};
};

type FormScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Demandes'>;

const FormSeychelles = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FormSeychelles'>>();
  const navigation = useNavigation<FormScreenNavigationProp>();
  const demandeId = route.params?.demandeId;
  const isEditMode = demandeId != null;
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState(route.params?.title);
  const [prixconsulat, setPrixconsulat] = useState(route.params?.prixconsulat);
  const [prixintervention, setPrixintervention] = useState(route.params?.prixintervention);
  const [urlFlag, setUrlFlag] = useState(route.params?.url_flag);
  const [validite, setValidite] = useState(route.params?.validite);
  const [informations, setInformations] = useState(route.params?.informations);

  const [dateDepart, setDateDepart] = useState('');
  const [dateSortie, setDateSortie] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [sliderValue, setSliderValue] = useState(false);

  // New state variables
  const [paysVisites, setPaysVisites] = useState('');
  const [justificatifHebergementUri, setJustificatifHebergementUri] = useState(null);
  const [billetAvionUri, setBilletAvionUri] = useState(null);

  const total = prixconsulat + prixintervention + (assuranceVisa === 'oui' ? 20 : 0);

  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const parseDate = (dateStr: string) => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      }
      return null;
    };

    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);

    const errors = [];

    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push('Date de départ');
    }

    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || sortieDate <= departDate!) {
        errors.push('Date de retour');
      }
    } else if (dateSortie && !sortieDate) {
      errors.push('Date de retour');
    }

    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");

    if (!paysVisites.trim()) errors.push('Pays visités 14 jours précédant les Seychelles');
    if (!justificatifHebergementUri) errors.push("Justificatif d'hébergement");
    if (!billetAvionUri) errors.push("Billet d'avion Aller - Retour");

    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };

  useEffect(() => {
    validateForm();
  }, [
    dateDepart,
    dateSortie,
    assuranceVisa,
    sliderValue,
    selectedVoyageur,
    paysVisites,
    justificatifHebergementUri,
    billetAvionUri,
  ]);

  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);

        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur('');
        }
      }
    };

    fetchVoyageurs();
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find((v) => v.id.toString() === selectedVoyageur);

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm(
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?'
          );
          if (confirmation) {
            navigation.goBack();
          }
        } else {
          Alert.alert(
            'Attention',
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?',
            [
              {
                text: 'Annuler',
                onPress: () => null,
                style: 'cancel',
              },
              {
                text: 'OK',
                onPress: () => navigation.goBack(),
              },
            ],
            { cancelable: false }
          );
        }
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', onBackPress);
      const unsubscribe = navigation.addListener('beforeRemove', (e) => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm(
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?'
          );
          if (confirmation) {
            navigation.dispatch(e.data.action);
          }
        } else {
          e.preventDefault();
          Alert.alert(
            'Attention',
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?',
            [
              { text: 'Annuler', style: 'cancel', onPress: () => {} },
              { text: 'OK', style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
            ]
          );
        }
      });

      return () => {
        backHandler.remove();
        unsubscribe();
      };
    }, [navigation])
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = await AsyncStorage.getItem('@user');

      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;
      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        assuranceVisa: assuranceVisa,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        paysVisites: paysVisites,
        justificatifHebergementUri: justificatifHebergementUri,
        billetAvionUri: billetAvionUri,
      };

      await validerDemande(data);
      console.log('Demande validée avec succès');
      setIsLoading(false);
      navigation.navigate('Demandes', '');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    const chars = numbers.split('');
    let formattedInput = '';

    chars.forEach((char, index) => {
      if (index === 2 || index === 4) {
        formattedInput += '/';
      }
      formattedInput += char;
    });

    return formattedInput.slice(0, 10);
  };

  const disabledPressHandler = () => {};

  const backgroundColor = champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc';

  return (
    <KeyboardAwareScrollView style={styles.scrollView} extraHeight={250}>
      <View style={styles.container}>
        <Image source={{ uri: urlFlag }} style={styles.flag} />
        <Text style={styles.title}>Formulaire pour {title}</Text>
        <Text style={styles.libellest}>
          {validite}
          {'\n'}
          {informations}
        </Text>

        {!isEditMode && (
          <View>
            <View style={styles.pickerContainer}>
              <RNPickerSelect
                onValueChange={(value) => setSelectedVoyageur(value)}
                items={voyageurs.map((voyageur) => ({
                  label: `${voyageur.nom} ${voyageur.prenom}`,
                  value: voyageur.id.toString(),
                }))}
                placeholder={{
                  label: 'Sélectionner un voyageur',
                  value: null,
                }}
                style={pickerSelectStyles}
                value={selectedVoyageur}
                useNativeAndroidPickerStyle={false}
              />
            </View>
          </View>
        )}
        {selectedVoyageurObj && (
          <View style={styles.avatarContainer}>
            <Image
              source={{ uri: selectedVoyageurObj.url || 'https://example.com/default-avatar.png' }}
              style={styles.avatar}
            />
          </View>
        )}

        <View style={styles.inputContainer}>
          <Text>Date de départ de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de départ de votre voyage jj/mm/aaaa"
            value={dateDepart}
            onChangeText={(text) => setDateDepart(formatDate(text))}
          />
        </View>

        <View style={styles.inputContainer}>
          <Text>Date de retour de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de retour de votre voyage jj/mm/aaaa"
            value={dateSortie}
            onChangeText={(text) => setDateSortie(formatDate(text))}
          />
        </View>

        {/* New Field: Pays visités 14 jours précédant les Seychelles */}
        <View style={styles.inputContainer}>
          <Text>Pays visités 14 jours précédant les Seychelles :</Text>
          <TextInput
            style={styles.textInput}
            placeholder="Liste des pays visités"
            value={paysVisites}
            onChangeText={setPaysVisites}
          />
        </View>

        {/* DocumentOrPhotoPickers */}
        <DocumentOrPhotoPicker
          documentType="Justificatif d'hébergement pour la totalité du séjour"
          onDocumentSelected={(uri: any) => setJustificatifHebergementUri(uri)}
          modeOptions={['photo', 'selectPhoto', 'selectPdf']}
        />

        <DocumentOrPhotoPicker
          documentType="Billet d'avion Aller - Retour"
          onDocumentSelected={(uri: any) => setBilletAvionUri(uri)}
          modeOptions={['photo', 'selectPhoto', 'selectPdf']}
        />

        <AssuranceVisaComponent
          assuranceVisa={assuranceVisa}
          setAssuranceVisa={setAssuranceVisa}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
        />

        <Text style={styles.libelle}>Frais de visa {prixconsulat}€</Text>
        <Text style={styles.libelle}>Frais de service {prixintervention}€</Text>
        <Text style={styles.totalText}>Total : {total}€</Text>

        <View style={[styles.champsNonRemplisContainer, { backgroundColor }]}>
          {champsNonRemplis.length > 0 ? (
            <>
              <Text style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
                Formulaire incomplet.
              </Text>
              {champsNonRemplis.map((champ, index) => (
                <Text key={index}>{champ}</Text>
              ))}
            </>
          ) : (
            <Text>Formulaire complet</Text>
          )}
        </View>

        {isLoading ? (
          <ActivityIndicator size="large" color="#0000ff" />
        ) : (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={isFormValid ? handleSubmit : disabledPressHandler}
              style={[styles.button, { backgroundColor: isFormValid ? 'black' : '#A9A9A9' }]}
              disabled={!isFormValid}
            >
              <Text style={styles.buttonText}>Valider ma demande</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  // ... existing styles ...
  totalText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  champsNonRemplisContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#d3d3d3',
  },
  inputContainer: {
    marginBottom: 15,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginTop: 5,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#eeeeee',
  },
  flag: {
    width: 50,
    height: 30,
    marginBottom: 10,
    alignSelf: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 3,
    textAlign: 'center',
  },
  libelle: {
    fontSize: 15,
    marginBottom: 2,
    textAlign: 'center',
  },
  libellest: {
    fontSize: 15,
    marginBottom: 20,
    textAlign: 'center',
  },
  pickerContainer: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 50,
  },
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
});

export default FormSeychelles;
