import React from 'react';
import {  Grid } from '@mui/material';
import { Image, Text, TouchableOpacity, StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Linking } from 'react-native';
import { ScrollView } from 'react-native';  // Assurez-vous que ScrollView est importé.
import AsyncStorage from '@react-native-async-storage/async-storage';

const storeUrlInAsyncStorage = async (url) => {
    try {
        await AsyncStorage.setItem('@pays_url_infos', url);
        console.log('URL stockée:', url);
    } catch (e) {
        console.error('Erreur lors de la sauvegarde de l\'URL:', e);
    }
};

const PaysGrid = ({ paysVisibles, onSelectPays }) => {
    return (
        <ScrollView style={styles.scrollView}>
            <Grid container spacing={2}>
                {paysVisibles.map((pays, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <TouchableOpacity onPress={() => onSelectPays(pays.id)} style={styles.cardContainer}>
                            <Image source={{ uri: pays.url_flag }} style={styles.flagImage} />
                            <Text style={styles.cardTitle}>{pays.libelle}</Text>
                            <Image source={{ uri: pays.url_image }} style={styles.countryImage} />
                            <TouchableOpacity
                                onPress={() => {
                                    const fullUrl = pays.url_infos + '?appli=yes';
                                    storeUrlInAsyncStorage(fullUrl);
                                    Linking.openURL(fullUrl);
                                }}
                                style={styles.infoIcon}
                            >
                                <Icon name="help-outline" size={30} color="red" />
                            </TouchableOpacity>
                        </TouchableOpacity>
                    </Grid>
                ))}
            </Grid>
        </ScrollView>
    );
};

// Styles mis à jour avec ScrollView
const styles = StyleSheet.create({
    scrollView: {
        width: '100%', // Assurez-vous que le ScrollView prend toute la largeur
        height: '100%' // Assurez-vous que le ScrollView prend toute la hauteur
    },
    cardContainer: {
        padding: 10,
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4,
        margin: 5
    },
    flagImage: {
        width: 40,
        height: 30,
        resizeMode: 'contain',
        marginTop: 5
    },
    cardTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center'
    },
    countryImage: {
        width: '90%',
        height: 100,
        resizeMode: 'cover',
        borderRadius: 10,
        marginBottom: 10
    },
    infoIcon: {
        marginBottom: 10,
        justifyContent: 'center',  // Centrer verticalement
        alignItems: 'center',      // Centrer horizontalement
        width: 40,                 // Fixer une largeur
        height: 40,                // Fixer une hauteur
        display: 'flex',           // Activer le mode flexbox
        flexDirection: 'row',      // Alignement horizontal
    }
});

export default PaysGrid;
