import axios from 'axios';

// Fonction pour déterminer le type MIME en fonction de l'extension du fichier
const getFileType = (uri) => {
  if (uri.startsWith('data:image/jpeg')) return 'image/jpeg';
  if (uri.startsWith('data:image/png')) return 'image/png';
  if (uri.startsWith('data:application/pdf')) return 'application/pdf';
  
  const extension = uri.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};


// Mise à jour de la fonction pour accepter les nouveaux paramètres
const creationVoyageur = async (data) => {
  const formData = new FormData();

  // Ajouter les champs texte
  formData.append('nom', data.nom);
  formData.append('prenom', data.prenom);
  //formData.append('pseudo', data.pseudo);
  formData.append('idUtilisateur', data.idUtilisateur);

  // Gestion conditionnelle des champs adresse
  
    formData.append('adresse', data.adresse);
    formData.append('codePostal', data.codePostal);
    formData.append('ville', data.ville);
    formData.append('profession', data.profession);
    formData.append('telephone', data.telephone);
    formData.append('etatCivil', data.etatCivil); // Ajout de l'état civil

  

  // Ajout de la photo
  // Gérer la photo
  if (data.photoUri.startsWith('data:')) {
    formData.append('photo', data.photoUri);
  } else {
    formData.append('photo', {
      uri: data.photoUri,
      type: getFileType(data.photoUri),
      name: `photo.${data.photoUri.split('.').pop()}`
    });
  }

  // Ajout du passeport c
  if (data.passeportUri.startsWith('data:')) {
    formData.append('passeport', data.passeportUri);
  } else {
    formData.append('passeport', {
      uri: data.passeportUri,
      type: getFileType(data.passeportUri),
      name: `passeport.${data.passeportUri.split('.').pop()}`
    });
  }

  try {
    const response = await axios.post('https://nodejs-appli.visamonde.com/insertVoyageur', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Voyageur créé avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la création du voyageur:', error);
  }
};

export default creationVoyageur;
