import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, Image, ScrollView, TouchableOpacity, ActivityIndicator, Alert, BackHandler, Platform } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useRoute, RouteProp, useNavigation, useFocusEffect } from '@react-navigation/native';
import { recupereDonneesDemande, recupererListeVoyageurs, updateDemande } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { validerDemande } from '../creationDemandeService';
import DocumentOrPhotoPicker from '../components/DocumentOrPhotoPicker';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { KeyboardAvoidingView } from 'react-native';

type FormScreenRouteParams = {
  title: string,
  prixconsulat: number,
  prixintervention: number,
  demandeId: number,
  url_flag: string,
};

type RootStackParamList = {
  FormScreen: FormScreenRouteParams;
  Demandes: {};
};

type FormScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Demandes'>;

const FormPakistan = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FormScreen'>>();
  const navigation = useNavigation<FormScreenNavigationProp>();
  const demandeId = route.params?.demandeId;
  const isEditMode = demandeId != null;
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState(route.params?.title);
  const [prixconsulat, setPrixconsulat] = useState(route.params?.prixconsulat);
  const [prixintervention, setPrixintervention] = useState(route.params?.prixintervention);
  const [urlFlag, setUrlFlag] = useState(route.params?.url_flag);

  const [dateDepart, setDateDepart] = useState('');
  const [dateSortie, setDateSortie] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState('');
  const [etatMatrimonial, setEtatMatrimonial] = useState('');
  const [nomPere, setNomPere] = useState('');
  const [nationalitePere, setNationalitePere] = useState('');
  const [nomMere, setNomMere] = useState('');
  const [nationaliteMere, setNationaliteMere] = useState('');
  const [profession, setProfession] = useState('');
  const [nomEmployeur, setNomEmployeur] = useState('');
  const [adresseEmployeur, setAdresseEmployeur] = useState('');
  const [numeroEmployeur, setNumeroEmployeur] = useState('');
  const [emailEmployeur, setEmailEmployeur] = useState('');
  const [dateEmbauche, setDateEmbauche] = useState('');
  const [attestationCircuitTouristiqueUri, setAttestationCircuitTouristiqueUri] = useState(null);
  const [certificatHebergementUri, setCertificatHebergementUri] = useState(null);
  const [lettreInvitationEBILUri, setLettreInvitationEBILUri] = useState(null);
  const [certificatEnregistrementEntrepriseUri, setCertificatEnregistrementEntrepriseUri] = useState(null);

  const updateChampsNonRemplis = () => {
    const champs = [];
    if (!dateDepart.trim()) champs.push('Date de départ');
    if (!dateSortie.trim()) champs.push('Date de sortie');
    if (!assuranceVisa.trim()) champs.push('Assurance Visa');
    if (!etatMatrimonial.trim()) champs.push('État matrimonial');
    if (!nomPere.trim()) champs.push('Nom et prénom du père');
    if (!nationalitePere.trim()) champs.push('Nationalité du père');
    if (!nomMere.trim()) champs.push('Nom et prénom de la mère');
    if (!nationaliteMere.trim()) champs.push('Nationalité de la mère');
    if (!profession.trim()) champs.push('Profession');
    if (!nomEmployeur.trim()) champs.push('Nom de l\'employeur');
    if (!adresseEmployeur.trim()) champs.push('Adresse de l\'employeur');
    if (!numeroEmployeur.trim()) champs.push('Numéro de téléphone de l\'employeur');
    if (!emailEmployeur.trim()) champs.push('Email de l\'employeur');
    if (!dateEmbauche.trim()) champs.push('Date d\'embauche');
    if (title.includes('Tourisme')) {
      if (!attestationCircuitTouristiqueUri) champs.push('Attestation de circuit touristique');
      if (!certificatHebergementUri) champs.push('Certificat d\'hébergement');
    }
    if (title.includes('Affaire')) {
      if (!lettreInvitationEBILUri) champs.push('Lettre d\'invitation électronique (E-BIL)');
      if (!certificatEnregistrementEntrepriseUri) champs.push('Certificat d\'enregistrement de l\'entreprise');
    }
    setChampsNonRemplis(champs);
  };

  useEffect(() => {
    updateChampsNonRemplis();
  }, [
    dateDepart, dateSortie, certificatEnregistrementEntrepriseUri, lettreInvitationEBILUri, certificatHebergementUri,
    attestationCircuitTouristiqueUri, etatMatrimonial, nomPere, nationalitePere, nomMere, nationaliteMere, profession,
    nomEmployeur, adresseEmployeur, numeroEmployeur, emailEmployeur, dateEmbauche, assuranceVisa
  ]);

  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length > 0) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        }
      }
    };
    fetchVoyageurs().then(() => {});
  }, [demandeId]);

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.goBack();
          }
        } else {
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              {
                text: 'Annuler',
                onPress: () => null,
                style: 'cancel',
              },
              {
                text: 'OK',
                onPress: () => navigation.goBack(),
              },
            ],
            { cancelable: false }
          );
        }
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      const unsubscribe = navigation.addListener('beforeRemove', (e) => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.dispatch(e.data.action);
          }
        } else {
          e.preventDefault();
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              { text: "Annuler", style: 'cancel', onPress: () => {} },
              { text: "OK", style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
            ]
          );
        }
      });

      return () => {
        backHandler.remove();
        unsubscribe();
      };
    }, [navigation])
  );

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = await AsyncStorage.getItem('@user');
     
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        assuranceVisa: assuranceVisa,
        certificatEnregistrementEntrepriseUri, lettreInvitationEBILUri, certificatHebergementUri, attestationCircuitTouristiqueUri,
        etatMatrimonial, nomPere, nationalitePere, nomMere, nationaliteMere, profession, nomEmployeur, adresseEmployeur, 
        numeroEmployeur, emailEmployeur, dateEmbauche,
      };

      await validerDemande(data);
      console.log('Demande validée avec succès');
      setIsLoading(false);
      navigation.navigate('Demandes', '');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    const chars = numbers.split('');
    let formattedInput = '';
    chars.forEach((char, index) => {
      if (index === 2 || index === 4) {
        formattedInput += '/';
      }
      formattedInput += char;
    });
    return formattedInput.slice(0, 10);
  };

  const backgroundColor = champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc';

  const disabledPressHandler = () => {};

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <KeyboardAwareScrollView style={styles.scrollView} extraHeight={250}>
        <View style={styles.container}>
          <Text style={styles.title}>Formulaire pour {title}</Text>
          <Image source={{ uri: urlFlag }} style={styles.flag} />
          <Text style={styles.libelle}>Prix consulat {prixconsulat}€ Prix intervention {prixintervention}€</Text>
          {!isEditMode && (
            <View>
              <Text style={styles.libelle}>Sélectionner un voyageur</Text>
              <View style={styles.pickerContainer}>
                <RNPickerSelect
                  onValueChange={(value) => setSelectedVoyageur(value)}
                  items={voyageurs.map((voyageur) => ({
                    label: `${voyageur.nom} ${voyageur.prenom}`,
                    value: voyageur.id.toString(),
                  }))}
                  placeholder={{
                    label: 'Sélectionner un voyageur',
                    value: null,
                  }}
                  style={pickerSelectStyles}
                  value={selectedVoyageur}
                  useNativeAndroidPickerStyle={false}
                />
              </View>
            </View>
          )}
          {selectedVoyageurObj && (
            <View style={styles.avatarContainer}>
              <Image
                source={{ uri: selectedVoyageurObj.url || 'https://example.com/default-avatar.png' }}
                style={styles.avatar}
              />
            </View>
          )}

          <View style={styles.inputContainer}>
            <Text>Date de départ de votre voyage :</Text>
            <TextInput
              style={[styles.textInput]}
              placeholder="Date de départ de votre voyage jj/mm/aaaa"
              value={dateDepart}
              onChangeText={(text) => setDateDepart(formatDate(text))}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Date de retour de votre voyage :</Text>
            <TextInput
              style={[styles.textInput]}
              placeholder="Date de retour de votre voyage jj/mm/aaaa"
              value={dateSortie}
              onChangeText={(text) => setDateSortie(formatDate(text))}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>État matrimonial :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Votre état matrimonial"
              onChangeText={setEtatMatrimonial}
              value={etatMatrimonial}
            />
          </View>

          {title.includes('Tourisme') && (
            <View>
              <DocumentOrPhotoPicker
                documentType="Attestation de participation à un circuit organisé"
                onDocumentSelected={setAttestationCircuitTouristiqueUri}
                modeOptions={['photo', 'selectPhoto', 'selectPdf']}
              />
              <DocumentOrPhotoPicker
                documentType="Certificat d'hébergement"
                onDocumentSelected={setCertificatHebergementUri}
                modeOptions={['photo', 'selectPhoto', 'selectPdf']}
              />
            </View>
          )}

          {title.includes('Affaire') && (
            <View>
              <DocumentOrPhotoPicker
                documentType="Lettre d'invitation électronique (E-BIL)"
                onDocumentSelected={setLettreInvitationEBILUri}
                modeOptions={['photo', 'selectPhoto', 'selectPdf']}
              />
              <DocumentOrPhotoPicker
                documentType="Certificat d'enregistrement de l'entreprise"
                onDocumentSelected={setCertificatEnregistrementEntrepriseUri}
                modeOptions={['photo', 'selectPhoto', 'selectPdf']}
              />
            </View>
          )}

          <View style={styles.inputContainer}>
            <Text>Nom et prénom du père :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Entrez le nom et prénom du père"
              onChangeText={setNomPere}
              value={nomPere}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Nationalité du père :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Nationalité du père"
              onChangeText={setNationalitePere}
              value={nationalitePere}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Nom et prénom de la mère :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Entrez le nom et prénom de la mère"
              onChangeText={setNomMere}
              value={nomMere}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Nationalité de la mère :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Nationalité de la mère"
              onChangeText={setNationaliteMere}
              value={nationaliteMere}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Profession :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Votre profession"
              onChangeText={setProfession}
              value={profession}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Nom de l'employeur :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Nom de l'employeur"
              onChangeText={setNomEmployeur}
              value={nomEmployeur}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Adresse de l'employeur :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Adresse détaillée de l'employeur"
              onChangeText={setAdresseEmployeur}
              value={adresseEmployeur}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Numéro de téléphone de l'employeur :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Numéro de téléphone de l'employeur"
              onChangeText={setNumeroEmployeur}
              value={numeroEmployeur}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Email de l'employeur :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="E-mail de l'employeur"
              onChangeText={setEmailEmployeur}
              value={emailEmployeur}
            />
          </View>

          <View style={styles.inputContainer}>
            <Text>Date d'embauche :</Text>
            <TextInput
              style={styles.textInput}
              placeholder="Date d'embauche"
              onChangeText={setDateEmbauche}
              value={dateEmbauche}
            />
          </View>

          <AssuranceVisaComponent
            assuranceVisa={assuranceVisa}
            setAssuranceVisa={setAssuranceVisa}
          />

          <View style={[styles.champsNonRemplisContainer, { backgroundColor }]}>
            {champsNonRemplis.length > 0 ? (
              <>
                <Text>Formulaire incomplet. Champs manquants :</Text>
                {champsNonRemplis.map((champ, index) => (
                  <Text key={index}>{champ}</Text>
                ))}
              </>
            ) : (
              <Text>Formulaire complet</Text>
            )}
          </View>

          {isLoading ? (
            <ActivityIndicator size="large" color="#0000ff" />
          ) : (
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                onPress={champsNonRemplis.length === 0 ? handleSubmit : disabledPressHandler}
                style={[
                  styles.button,
                  { backgroundColor: champsNonRemplis.length === 0 ? '#007AFF' : '#A9A9A9' },
                ]}
                disabled={champsNonRemplis.length !== 0}
              >
                <Text style={styles.buttonText}>Valider ma demande</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </KeyboardAwareScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  radioButtonOption: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'grey',
    padding: 10,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007AFF',
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
  },
  cameraContainer: {
    position: 'absolute',
    left: 0,
    width: 430,
    height: 750,
  },
  imagePickerButton: {
    backgroundColor: '#4e9af1',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: '80%',
    alignSelf: 'center',
  },
  imagePreview: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
  },
  champsNonRemplisContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInput: {
    marginBottom: 20,
    width: '100%',
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#D3D3D3',
  },
  inputContainer: {
    marginBottom: 15,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginTop: 5,
    backgroundColor: 'white',
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#D3D3D3',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  libelle: {
    fontSize: 15,
    marginBottom: 20,
  },
  pickerContainer: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 50,
  },
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  flag: {
    width: 100,
    height: 60,
    alignSelf: 'center',
    marginBottom: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
});

export default FormPakistan;
