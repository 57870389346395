import axios from 'axios';

const BASE_URL = 'https://nodejs-appli.visamonde.com'; // Assurez-vous que BASE_URL est défini ici ou dans un fichier de configuration

// Fonction pour déterminer le type MIME en fonction de l'extension du fichier
const getFileType = (uri) => {
  if (uri.startsWith('data:image/jpeg')) return 'image/jpeg';
  if (uri.startsWith('data:image/png')) return 'image/png';
  if (uri.startsWith('data:application/pdf')) return 'application/pdf';
  
  const extension = uri.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};

// Fonction générique pour valider le formulaire
const validerDemande = async (data) => {
  const formData = new FormData();

  // Parcourir les champs de `data`
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'string' && value.startsWith('data:')) {
      // Cas d'un fichier encodé en base64
      formData.append(key, value);
      formData.append(`${key}Encoding`, 'base64'); // Indicateur pour le backend
    } else if (typeof value === 'string' && (value.startsWith('http') || value.startsWith('file:'))) {
      // Cas d'un fichier avec une URI locale ou distante
      formData.append(key, {
        uri: value,
        type: getFileType(value),
        name: `${key}.${value.split('.').pop()}`
      });
    } else {
      // Cas d'un champ texte standard
      formData.append(key, value);
    }
  }

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await axios.post(`${BASE_URL}/validerDemande`, formData, config);
    console.log('Demande validée avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la validation de la demande:', error);
  }
};

export default validerDemande;
