import React, { useState, useRef, useEffect } from 'react';
import { View, Button, Image, Text, StyleSheet } from 'react-native';
import { Camera } from 'expo-camera';
import axios from 'axios';
import Slider from '@react-native-community/slider';

const MyCameraComponent = ({ onPhotoTaken, onCancel }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [photoUri, setPhotoUri] = useState(null);
  const cameraRef = useRef(null);
  const [cameraType, setCameraType] = useState(Camera.Constants.Type.back);
  const [zoom, setZoom] = useState(0);

  console.log("Hello");
  console.log(Camera);
  console.log(Camera.Constants);

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  const takePicture = async () => {
    if (cameraRef.current) {
      const photo = await cameraRef.current.takePictureAsync();
      setPhotoUri(photo.uri);
      onPhotoTaken(photo.uri);
    }
  };

  const sendPhotoToServer = async () => {
    if (!photoUri) return;

    const formData = new FormData();
    formData.append('photo', {
      uri: photoUri,
      type: 'image/jpeg',
      name: 'photo.jpg',
    });

    try {
      const response = await axios.post('http://192.168.1.144:3001/uploadPhoto', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Photo envoyée avec succès:', response.data);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la photo:', error);
    }
  };

  const flipCamera = () => {
    setCameraType(
      cameraType === Camera.Constants.Type.back
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back
    );
  };

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={styles.container}>
      {photoUri ? (
        <View style={styles.previewContainer}>
          <Image source={{ uri: photoUri }} style={styles.previewImage} />
          <Button title="Envoyer au serveur" onPress={sendPhotoToServer} />
        </View>
      ) : (
        <Camera 
          style={styles.camera} 
          type={cameraType}
          ref={cameraRef}
          zoom={zoom}
          autoFocus={Camera.Constants.AutoFocus.on}
        >
          <View style={styles.buttonContainer}>
            <Button title="Inverser la caméra" onPress={flipCamera} />
            <Button title="Prendre une photo" onPress={takePicture} />
            <Button title="Annuler" onPress={onCancel} />
            <Slider
              style={styles.zoomSlider}
              minimumValue={0}
              maximumValue={0.01}
              value={zoom}
              onValueChange={handleZoomChange}
            />
          </View>
        </Camera>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  zoomSlider: {
    width: '100%',
    position: 'absolute',
    bottom: 100,
  },
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  previewContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    width: '100%',
    height: '80%',
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    margin: 20,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});

export default MyCameraComponent;
