import React, { useState, useEffect, useCallback } from 'react';
import { ActivityIndicator, View, Text, FlatList, StyleSheet, Image, ImageBackground, TouchableOpacity, Button, Linking, ScrollView, Platform } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Demandes } from '../models/Demandes';
import { recupererListeDemandes, supprimerDemandeService, payer } from '../Services';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from '../components/Header';
import { Alert } from 'react-native';
import AdresseFacturation from '../components/AdresseFacturation';



type RootStackParamList = {
  DemandeDetail: { demande: Demandes };
  WebviewScreen: { url: string };
  Paiement: { bonId : number, urlPaiement: string };
  FormScreen: { demandeId: number };
  
};

type DemandesScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'DemandeDetail'>;

const DemandesScreen = () => {
  const navigation = useNavigation<DemandesScreenNavigationProp>();
  //navigation.navigate('WebviewScreen', { url: urlPaiement });

  const [isLoading, setIsLoading] = useState(false); // État pour gérer l'indicateur de chargement

  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const handleSelect = (id: number) => {
    if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
    } else {
        setSelectedIds([...selectedIds, id]);
    }
};

const fetchDemandes = async () => {
  try {
    const userJson = await AsyncStorage.getItem('@user');
    if (userJson !== null) {
      const user = JSON.parse(userJson);
      const userId = user.id_utilisateur; // Assurez-vous que c'est la bonne clé pour l'ID de l'utilisateur
      const demandesData = await recupererListeDemandes(userId);
      setDemandes(demandesData);
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des demandes:', error);
  }
};

useEffect(() => {
  fetchDemandes();
}, []);

useFocusEffect(
  useCallback(() => {
    fetchDemandes();
    setSelectedIds([]); // Réinitialiser les IDs sélectionnés
  }, [])
);

const accederFacture = (cheminDemande:any) => {
  const url = `https://visamonde.com/demandes/${cheminDemande}/pax.pdf`;

  // Vérifier si l'URL peut être ouverte
  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        // Ouvrir l'URL dans le navigateur par défaut
        Linking.openURL(url);
      } else {
        console.log("Ne peut pas ouvrir l'URL: " + url);
      }
    })
    .catch((err) => console.error("Une erreur est survenue", err));
};

const accederAssurance = (cheminDemande:any) => {
  const url = `https://visamonde.com/demandes/${cheminDemande}/assurance.pdf`;

  // Vérifier si l'URL peut être ouverte
  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        // Ouvrir l'URL dans le navigateur par défaut
        Linking.openURL(url);
      } else {
        console.log("Ne peut pas ouvrir l'URL: " + url);
      }
    })
    .catch((err) => console.error("Une erreur est survenue", err));
};

const handlePayer = async () => {
    setIsLoading(true); // Commencer le chargement
    try {
      const { urlPaiement, bonId } = await payer(selectedIds);
      console.log('URL de paiement:', urlPaiement);
      console.log('BDC:', bonId);
       // Naviguer vers AdresseFacturation.tsx avec l'ID du BDC et l'URL de paiement
       navigation.navigate('Paiement', { bonId, urlPaiement });


      //Linking.openURL(urlPaiement);
    } catch (error) {
      console.error('Erreur lors du paiement:', error);
    } finally {
      setIsLoading(false); // Arrêter le chargement
    }
  };

  const handleDeleteDemandeConfirmation = (id: number) => {
    if (Platform.OS === 'web') {
      const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette demande ?");
      if (isConfirmed) {
        handleDeleteDemande(id);
      }
    } else {
      Alert.alert(
        "Confirmer la suppression",
        "Êtes-vous sûr de vouloir supprimer cette demande ?",
        [
          {
            text: "Annuler",
            onPress: () => console.log("Suppression annulée"),
            style: "cancel"
          },
          { 
            text: "OK", 
            onPress: () => handleDeleteDemande(id)
          }
        ],
        { cancelable: false }
      );
    }
  };
  
  const handleDeleteDemande = async (id: number) => {
    setIsLoading(true); // Commencer le chargement
    try {
      await supprimerDemandeService(id); // Suppression de la demande via le service
  
      // Retirer l'ID supprimé de la liste des IDs sélectionnés
      const updatedSelectedIds = selectedIds.filter(selectedId => selectedId !== id);
      setSelectedIds(updatedSelectedIds);
  
      // Si plus aucune demande n'est sélectionnée, cacher le bouton de paiement
      if (updatedSelectedIds.length === 0) {
        console.log('Aucune demande sélectionnée, cacher le bouton de paiement');
      }
  
      // Rafraîchir la liste des demandes après la suppression
      fetchDemandes();
    } catch (error) {
      console.error('Erreur lors de la suppression de la demande:', error);
    } finally {
      setIsLoading(false); // Arrêter le chargement
    }
  };
  
  
  const handleCardClick = (item: Demandes) => {
    if (item.etat_paiement !== 'payé' && item.etat_paiement !== 'facturé') {
      handleSelect(item.id!);
    }
  };

  const handlePressFormScreen = (demandeId: number) => {
    //navigation.navigate('FormScreen', { demandeId });
  };
  
  // Déterminez la largeur basée sur la plateforme et le nombre de demandes
  const getGridItemWidth = () => {
    if (Platform.OS === 'web') {
      return demandes.length === 1 ? '90%' : '45%';  // Mobile settings
    } else {
      return demandes.length === 1 ? '90%' : '45%';  // Mobile settings
    }
  };

// Dans votre composant DemandesScreen, avant le return du JSX
const gridItemWidth = getGridItemWidth();


  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <Header /> 
  
      <TouchableOpacity 
        style={styles.fullWidthFooterCard} 
        onPress={() => navigation.getParent()?.navigate('Destinations')}
      >
        <Icon name="add" size={50} color="#000" />
        <Text style={styles.footerText}>Créer une demande</Text>
      </TouchableOpacity>

      <View style={styles.instructionContainer}>
        <Text style={styles.instructionText}>
          Pour payer, cliquer sur une ou plusieurs demande.
        </Text>
      </View>
  
      {isLoading ? (
        <ActivityIndicator size="large" color="#0000ff" style={styles.loadingIndicator} />
      ) : (
        <ScrollView contentContainerStyle={styles.grid}>
        {demandes.map((item, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => handleCardClick(item)}
            style={[
              styles.cardTouchable,
              {
                width: gridItemWidth,
              }
            ]}
          >
            <View style={[
              styles.card,
              // Ajoutez ici la logique de bordure
              item.alerte === 'oui'
                ? { borderColor: 'red' }  // Bordure rouge si alerte
                : item.etat_visa === 'en cours'
                ? { borderColor: 'green' } // Bordure verte si état "en cours"
                : item.infosdemande
                ? { borderColor: '#00FF00' } // Bordure verte si infosdemande
                : { borderColor: '#ddd' }, // Bordure grise par défaut
              { borderWidth: 5 }, // Applique une bordure égale pour toutes les conditions
            ]}>
              <Text>Dossier Nº{item.id}</Text>
              <Image source={{ uri: item.urlpays }} style={styles.flag} />
              <Text style={styles.titre}>{item.titre}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Icon name="flight-takeoff" size={20} color="#333" />
                <Text style={{ marginLeft: 5 }}>{item.datedepart}</Text>
              </View>
              <Image source={{ uri: item.urlvoyageur }} style={styles.avatar} />
              <Text>{`${item.prenom}`}</Text>
              <Text>{`${item.nom}`}</Text>
      
              {/* Afficher "En cours de traitement" si l'état du visa est "en cours" */}
              {item.etat_visa === 'en cours' && (
                <Text style={{ fontWeight: 'bold', color: 'green' }}>
                  En cours de traitement
                </Text>
              )}
      
              {item.assurancevisa === 'oui' && (
                <>
                  <Text>Assurance: 20€</Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    Prix Total: {(item.prixconsulat || 0 ) + (item.prixintervention || 0) + 20}€
                  </Text>
                </>
              )}
              {item.assurancevisa !== 'oui' && (
                <Text style={{ fontWeight: 'bold' }}>
                  Prix Total: {(item.prixconsulat || 0) + (item.prixintervention || 0)}€
                </Text>
              )}
      
              <Text>
                {'\n'}
                <Text style={{fontWeight: 'bold'}}>
                  {item.infosdemande}
                </Text>
                {'\n'}
              </Text>                
              <Text>{item.etat_paiement}</Text>
      
              {item.etat_paiement === 'facturé' && (
                <TouchableOpacity 
                  style={styles.iosButton} 
                  onPress={() => accederFacture(item.chemin)}
                >
                  <Text style={styles.iosButtonText}>Accéder à ma facture</Text>
                </TouchableOpacity>
              )}
      
      
              {item.etat_paiement !== 'payé' && item.etat_paiement !== 'facturé' && (
                <TouchableOpacity style={styles.deleteIcon} onPress={() => handleDeleteDemandeConfirmation(item.id!)}>
                  <Icon name="delete" size={24} color="#FF0000" />
                </TouchableOpacity>
              )}
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
      

      )}


  
      {selectedIds.length > 0 && (
        <View style={styles.menuContextuel}>
          <TouchableOpacity 
      style={styles.paiementButton}
      onPress={handlePayer}
    >
      <Text style={styles.paiementButtonText}>Accéder au paiement</Text>
    </TouchableOpacity>
        </View>
      )}
    </ImageBackground>
  );
  
  
};

const styles = StyleSheet.create({
  fullWidthFooterCard: {
    width: '90%', // Le bouton prend 90% de la largeur de l'écran
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 20, // Espace vertical pour le bouton
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    borderRadius: 10, // Bords arrondis
    alignSelf: 'center', // Centre le bouton horizontalement
    marginTop: 10, // Espace au-dessus du bouton
    marginBottom: 10, // Espace en-dessous du bouton
  },
  footerText: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },  
  insuranceIconContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 5, // Ajustez selon vos besoins
  },
  iosButton: {
    backgroundColor: '#007AFF',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    alignSelf: 'center', // Centrer le bouton dans la carte
  },
  iosButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center', // Centrer le texte dans le bouton
  },
  etatVisaComplet: {
    color: 'green',
    fontWeight: 'bold',
    textAlign:'center',
  },
  etatVisaIncomplet: {
    color: 'red',
    fontWeight: 'bold',
    textAlign:'center',
  },
  paiementButton: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
  },
  paiementButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  grid: {
    flexDirection: 'row', 
    flexWrap: 'wrap', 
    justifyContent: 'space-around',
  },
  cardTouchable: {
    width: '45%', // Ajustez la largeur selon le design souhaité
    margin: 5,
    // ... autres styles pour cardTouchable ...
  },
  container: {
    flex: 1,
    padding: 10,
  },

  loadingIndicator: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructionContainer: {
    padding: 10,
    backgroundColor: 'white', // Un fond blanc semi-transparent
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    width: '90%',
    borderRadius: 10, // Ajoute les bords arrondis
  },
  instructionText: {
    textAlign: 'center', // Centre le texte horizontalement
    color: '#333', // Couleur du texte
    fontSize: 14, // Taille du texte
    backgroundColor: 'white',
    
  },
    menuContextuel: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        padding: 10,
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        alignItems: 'center',
        width: '100%',
        
      },
    deleteIcon: {
        position: 'absolute',
        right: 1,
        bottom: 1,
      },
    listContainer: {
        paddingHorizontal: 10, // Ajustez selon vos besoins
      },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  card: {
    flex: 1,
    margin: 10,
    borderWidth: 3,
    borderColor: '#ddd',
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  titre: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign : 'center',
  },
  flag: {
    width: 40,
    height: 25,
    marginVertical: 10,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
});

export default DemandesScreen;
