import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';

interface CarouselItem {
  url: string;
}

interface ModalWebProps {
  isVisible: boolean;
  onClose: () => void;
  items: CarouselItem[];
}

const ModalWeb: React.FC<ModalWebProps> = ({ isVisible, onClose, items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!isVisible) return null;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  return (
    <div style={webStyles.webModalContainer}>
      <div style={webStyles.webModal}>
        <Text style={webStyles.modalTitle}>Tuto</Text>
        <div style={webStyles.carousel}>
          <button onClick={handlePrev} style={webStyles.navButton}>{"<"}</button>
          <div style={webStyles.carouselItem}>
            <img src={items[currentIndex].url} style={webStyles.carouselImage} alt={`carousel-item-${currentIndex}`} />
          </div>
          <button onClick={handleNext} style={webStyles.navButton}>{">"}</button>
        </div>
        <button style={webStyles.button} onClick={onClose}>
          <span style={webStyles.buttonText}>Commencer</span>
        </button>
      </div>
    </div>
  );
};

const webStyles = {
  webModalContainer: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  webModal: {
    width: '80%',
    height: '70%',
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold' as 'bold', // Use a recognized keyword or remove cast
    marginBottom: 20,
  },
  carousel: {
    display: 'flex',
    alignItems: 'center',
  },
  carouselItem: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  carouselImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  navButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '2rem',
    cursor: 'pointer',
  },
  button: {
    marginTop: 20,
    backgroundColor: 'tomato',
    padding: 10,
    borderRadius: 5,
    border: 'none',
    cursor: 'pointer',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold' as 'bold', // Use a recognized keyword or remove cast
  },
};

export default ModalWeb;
