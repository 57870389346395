import React, { useEffect, useState } from 'react';
import { View, Text, TextInput, StyleSheet, ImageBackground, Linking, TouchableOpacity, Alert, KeyboardAvoidingView } from 'react-native';
import { AdresseFacture } from '../models/AdresseFacture';
import { StackScreenProps } from '@react-navigation/stack';
import RNPickerSelect from 'react-native-picker-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { insertAdresseFacturation, recupereAdressesFacturation, updateAdresseFacturation } from '../Services';
import { useNavigation } from '@react-navigation/native'; 
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type RootStackParamList = {
  Paiement: {
    bonId: number;
    urlPaiement: string;
  };
};

type Props = StackScreenProps<RootStackParamList, 'Paiement'>;

const AdresseFacturation: React.FC<Props> = ({ route }) => {
  const navigation = useNavigation(); 
  const { bonId, urlPaiement } = route.params;
  const [adresses, setAdresses] = useState<AdresseFacture[]>([]);
  const [adresse, setAdresse] = useState<AdresseFacture>({
    id: 0,
    id_utilisateur: 0, 
    nom: '',
    adresse_ligne1: '',
    adresse_ligne2: '',
    ville: '',
    code_postal: '',
    pays: '',
    telephone: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchAdressesFacturation = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const adressesFacturation = await recupereAdressesFacturation(user.id_utilisateur);
        if (adressesFacturation && adressesFacturation.length > 0) {
          console.log(adressesFacturation);
          setAdresses(adressesFacturation);
          setAdresse(adressesFacturation[0]); 
        }
      }
    };

    fetchAdressesFacturation();
  }, []);

  const handleAdresseChange = (adresseId: number) => {
    console.log("Adresse ID:", adresseId);
  
    const idNum = Number(adresseId);
  
    const nouvelleAdresse = adresses.find(adr => adr.id === idNum);
    console.log("Nouvelle Adresse:", nouvelleAdresse);
  
    if (nouvelleAdresse) {
      setAdresse(nouvelleAdresse);
    }
  };
  
  const ajouterAdresse = () => {
    setAdresse({
      id: 0,
      id_utilisateur: 0,
      nom: '',
      adresse_ligne1: '',
      adresse_ligne2: '',
      ville: '',
      code_postal: '',
      pays: '',
      telephone: '',
    });
  };

  const validateForm = () => {
    if (
      adresse.nom.trim() === '' ||
      adresse.adresse_ligne1.trim() === '' ||
      adresse.ville.trim() === '' ||
      adresse.code_postal.trim() === '' ||
      adresse.pays.trim() === '' ||
      adresse.telephone.trim() === ''
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      Alert.alert('Erreur', 'Veuillez remplir tous les champs obligatoires.');
      return;
    }

    console.log('Adresse de facturation:', adresse);
  
    try {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson) {
        const user = JSON.parse(userJson);
        const idUtilisateur = user.id_utilisateur;
        
        if (adresse.id === 0) {
          await insertAdresseFacturation(idUtilisateur, bonId, adresse);
        } else {
          await updateAdresseFacturation(idUtilisateur, bonId, adresse);
        }
  
        Linking.openURL(urlPaiement);
        setIsFormSubmitted(true); 

      }
    } catch (error) {
      console.error('Erreur lors de la soumission de l\'adresse:', error);
    }
  };

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
        <KeyboardAwareScrollView
          contentContainerStyle={styles.scrollContainer}
          resetScrollToCoords={{ x: 0, y: 0 }}
          scrollEnabled={true}
        >
          <View style={styles.container}>
            {isFormSubmitted ? (
              <View style={styles.centeredView}>
                <TouchableOpacity onPress={() => navigation.goBack()} style={styles.simpleButton}>
                  <Text style={styles.simpleButtonText}>Retour</Text>
                </TouchableOpacity>
              </View>
            ) : (
              <>
                <Text style={styles.heading}>Adresse de Facturation</Text>
                {adresses.length > 0 && (
                  <>
                    <RNPickerSelect
                      onValueChange={handleAdresseChange}
                      items={adresses.map((adr) => ({ label: adr.nom, value: adr.id.toString() }))}
                      style={pickerSelectStyles}
                      placeholder={{
                        label: 'Choisir une adresse',
                        value: null,
                      }}
                    />
                   <TextInput
                 
                />
                  </>
                )}
                <TextInput
                  placeholder="Nom ou raison social"
                  style={styles.input}
                  value={adresse.nom}
                  onChangeText={(text) => setAdresse({ ...adresse, nom: text })}
                />
                <TextInput
                  placeholder="Adresse Ligne 1"
                  style={styles.input}
                  value={adresse.adresse_ligne1}
                  onChangeText={(text) => setAdresse({ ...adresse, adresse_ligne1: text })}
                />
                <TextInput
                  placeholder="Adresse Ligne 2"
                  style={styles.input}
                  value={adresse.adresse_ligne2}
                  onChangeText={(text) => setAdresse({ ...adresse, adresse_ligne2: text })}
                />
                <TextInput
                  placeholder="Ville"
                  style={styles.input}
                  value={adresse.ville}
                  onChangeText={(text) => setAdresse({ ...adresse, ville: text })}
                />
                <TextInput
                  placeholder="Code Postal"
                  style={styles.input}
                  value={adresse.code_postal}
                  onChangeText={(text) => setAdresse({ ...adresse, code_postal: text })}
                />
                <TextInput
                  placeholder="Pays"
                  style={styles.input}
                  value={adresse.pays}
                  onChangeText={(text) => setAdresse({ ...adresse, pays: text })}
                />
                <TextInput
                  placeholder="Téléphone"
                  style={styles.input}
                  value={adresse.telephone}
                  onChangeText={(text) => setAdresse({ ...adresse, telephone: text })}
                />

                 {/* N'affichez le bouton que s'il y a au moins une adresse */}
              {adresses.length > 0 && (
                <TouchableOpacity onPress={ajouterAdresse} style={styles.ajouterAdresseButton}>
                  <Text style={styles.ajouterAdresseText}>Créer une nouvelle adresse</Text>
                </TouchableOpacity>
              )}

                <TouchableOpacity onPress={handleSubmit} style={styles.customButton}>
                  <Text style={styles.customButtonText}>Valider et accéder au paiement</Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </KeyboardAwareScrollView>
      </KeyboardAvoidingView>
    </ImageBackground>
  );
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, 
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, 
  },
});

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: 20,
  },
  container: {
    backgroundColor: 'rgba(255,255,255,0.9)',
    borderRadius: 10,
    padding: 20,
    width: '100%',
  },
  centeredView: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  simpleButton: {
    backgroundColor: '#007bff', 
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  simpleButtonText: {
    color: '#ffffff', 
    fontSize: 16,
    fontWeight: 'bold',
  },
  customButton: {
    backgroundColor: 'black', 
    padding: 15, 
    borderRadius: 8, 
    marginTop: 20, 
    alignItems: 'center', 
    width: '100%', 
  },
  customButtonText: {
    color: 'white', 
    fontSize: 16, 
    fontWeight: 'bold', 
  },
  ajouterAdresseButton: {
    backgroundColor: 'black', 
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
    alignItems: 'center',
  },
  ajouterAdresseText: {
    color: 'white',
    fontSize: 16,
  },
  picker: {
    height: 50,
    width: '100%',
  },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    width: '100%',
  },
});

export default AdresseFacturation;
