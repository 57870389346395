import axios from 'axios';

// Fonction pour déterminer le type MIME en fonction de l'extension du fichier
const getFileType = (uri) => {
  const extension = uri.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream'; // Type générique pour les fichiers binaires
  }
};

const modifieVoyageur = async (idVoyageur, nom, prenom, newPhotoUri, newPaxUri, adresse, codePostal, ville, telephone, profession,etatCivil ) => {
  const formData = new FormData();
  formData.append('idVoyageur', idVoyageur);
  //formData.append('pseudo', pseudo);
   formData.append('nom', nom);
   formData.append('prenom', prenom);
  formData.append('adresse', adresse);
  formData.append('codePostal', codePostal);
  formData.append('ville', ville);
  formData.append('telephone', telephone);
  formData.append('profession', profession);
  formData.append('etatCivil', etatCivil);

  // Ajoute la photo uniquement si elle est fournie
  if (newPhotoUri) {
    if (newPhotoUri.startsWith('data:')) {
      formData.append('photo', newPhotoUri);
    } else {
      
    formData.append('photo', {
      uri: newPhotoUri,
      type: 'image/jpeg',
      name: 'photo.jpg',
    });
    }
  }

  
 
  // Ajoute le passeport uniquement si il est fourni
  if (newPaxUri) {
    const passeportType = getFileType(newPaxUri);
    const passeportName = 'passeport.' + newPaxUri.split('.').pop(); // Construit le nom de fichier avec l'extension appropriée
    if (newPaxUri.startsWith('data:')) {
      formData.append('passeport', newPaxUri);
    } else {
    formData.append('passeport', {
      uri: newPaxUri,
      type: passeportType,
      name: passeportName,
    });
    }
  }

  try {
    const response = await axios.post('https://nodejs-appli.visamonde.com/updateVoyageur', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Mise à jour réussie:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour du voyageur:', error);
  }
};

export default modifieVoyageur;
