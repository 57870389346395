import React, { useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Alert, Image, Modal, Platform } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import MyCameraComponent from './MyCameraComponent'; // Assurez-vous que le chemin est correct
import * as ImagePicker from 'expo-image-picker';
import FileInput from './FileInput'; // Assurez-vous que ce chemin est correct
import MyCameraComponentBis from './MyCameraComponentBis';

const DocumentOrPhotoPicker = ({ documentType, onDocumentSelected, modeOptions }) => {
  const [mode, setMode] = useState('none'); // 'none', 'camera', 'document'
  const [documentName, setDocumentName] = useState(null);
  const [photoUri, setPhotoUri] = useState(null);
  const [isCameraModalVisible, setIsCameraModalVisible] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [isExampleModalVisible, setIsExampleModalVisible] = useState(false);
  const [isPassportModalVisible, setIsPassportModalVisible] = useState(false);

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      copyToCacheDirectory: false
    });

    if (result.assets && result.assets.length > 0) {
      const documentName = result.assets[0].name;
      setDocumentName(documentName);
      setPhotoUri(null); // Effacer la photo lorsqu'un document est choisi
      setMode('document');
      onDocumentSelected(result.assets[0].uri); // Utilisez la fonction de rappel pour envoyer l'URI
    }
  };

  const handleCancel = () => {
    setMode('none');
    setIsCameraModalVisible(false);
  };

  const openPickerOrCamera = () => {
    if (documentType === "Photo d'identité") {
      setIsExampleModalVisible(true);
    } else if (documentType === "Passeport") {
      setIsPassportModalVisible(true);
    } else {
      openPickerOrCameraContinuation();
    }
  };

  const openPickerOrCameraContinuation = () => {
    if (modeOptions.length === 1) {
      const option = modeOptions[0];
      switch (option) {
        case 'photo':
          setIsCameraModalVisible(true);
          break;
        case 'selectPhoto':
        case 'selectPdf':
          pickDocument(option === 'selectPdf' ? 'application/pdf' : 'image/*');
          break;
        default:
          console.log("Option non reconnue");
      }
    } else {
      selectMode();
    }
  };

  const pickPhotoFromGallery = async () => {
    const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert('Vous devez autoriser l accès à vos photos pour utiliser cette fonctionnalité.');
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      //aspect: [4, 3],
      quality: 0.3,
    });

    if (result.assets && result.assets.length > 0) {
      const selectedImage = result.assets[0];
      const imageName = selectedImage.uri.split('/').pop();
      setDocumentName(null);
      setMode('none');
      setPhotoUri(selectedImage.uri);
      onDocumentSelected(result.assets[0].uri);
    }
  };

  const selectMode = () => {
    const options = [];

    if (modeOptions.includes('photo')) {
      options.push({ text: "Prendre une photo", onPress: () => setIsCameraModalVisible(true) });
    }
    if (modeOptions.includes('selectPhoto')) {
      options.push({ text: "Sélectionner une photo", onPress: pickPhotoFromGallery });
    }
    if (modeOptions.includes('selectPdf')) {
      options.push({ text: "Sélectionner un PDF", onPress: () => pickDocument('application/pdf') });
    }
    options.push({ text: "Annuler", style: "cancel" });

    Alert.alert(
      `Télécharger ${documentType}`,
      "Que souhaitez-vous faire ?",
      options
    );
  };

  const handlePhotoTaken = (uri) => {
    console.log("Photo prise à l'URI:", uri);
    setPhotoUri(uri);
    setDocumentName(null);
    setMode('none');
    setIsCameraModalVisible(false);
    onDocumentSelected(uri);
    console.log(uri);
  };

  const handleFileSelected = (fileData, fileName) => {
    console.log("File selected:", fileName);
    setDocumentName(fileName);
    setPhotoUri(fileData);
    setMode('document');
    onDocumentSelected(fileData);

    const fileExtension = fileName.split('.').pop();
    setIsPdf(fileExtension.toLowerCase() === 'pdf');
  };

  const handleExampleModalClose = () => {
    setIsExampleModalVisible(false);
    openPickerOrCameraContinuation();
  };

  const handlePassportModalClose = () => {
    setIsPassportModalVisible(false);
    openPickerOrCameraContinuation();
  };

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <>
          <FileInput onFileSelected={handleFileSelected} accept={documentType} />
          {photoUri && !isPdf && (
            <Image source={{ uri: photoUri }} style={styles.photo} />
          )}
          {photoUri && isPdf && (
            <Text style={styles.documentName}>PDF chargé: {documentName}</Text>
          )}
        </>
      ) : (
        <>
          <TouchableOpacity style={styles.iosButton} onPress={openPickerOrCamera}>
            <Text style={styles.iosButtonText}>{`Télécharger ${documentType}`}</Text>
          </TouchableOpacity>

          <Modal
            animationType="slide"
            transparent={false}
            visible={isCameraModalVisible}
            onRequestClose={() => setIsCameraModalVisible(false)}
          >
            <MyCameraComponentBis onPhotoTaken={handlePhotoTaken} onCancel={handleCancel} />
          </Modal>

          {mode === 'document' && documentName && !photoUri && (
            <Text style={styles.documentName}>{documentName}</Text>
          )}

          {photoUri && !documentName && (
            <Image source={{ uri: photoUri }} style={styles.photo} />
          )}

          <Modal
            animationType="slide"
            transparent={true}
            visible={isExampleModalVisible}
            onRequestClose={() => setIsExampleModalVisible(false)}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.modalText}>Exemple de photo</Text>
                <Image
                  source={require('../assets/photoexemple.jpg')} // Utilisez require pour l'image locale
                  style={styles.modalImage}
                />

                <TouchableOpacity
                  style={[styles.button, styles.buttonClose]}
                  onPress={handleExampleModalClose}
                >
                  <Text style={styles.textStyle}>J'ai compris</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={isPassportModalVisible}
            onRequestClose={() => setIsPassportModalVisible(false)}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Text style={styles.modalText}>Exemple de passeport</Text>
                <Image
                  source={require('../assets/paxexemple.jpg')} // Utilisez require pour l'image locale
                  style={styles.modalImage}
                />

                <TouchableOpacity
                  style={[styles.button, styles.buttonClose]}
                  onPress={handlePassportModalClose}
                >
                  <Text style={styles.textStyle}>J'ai compris</Text>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  iosButton: {
    backgroundColor: '#3eab36',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginBottom: 10,
  },
  iosButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  container: {
    marginTop: 20,
    marginBottom: 10,
  },
  documentName: {
    marginTop: 20,
    fontSize: 16,
    color: 'black',
    alignSelf: 'center',
  },
  photo: {
    width: 200,
    height: 200,
    marginTop: 20,
    alignSelf: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 18,
  },
  modalImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: 'black',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default DocumentOrPhotoPicker;
