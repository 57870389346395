import React, { useRef } from 'react';

const FileInput = ({ onFileSelected, accept }) => {
  const fileInputRef = useRef(null);

  const handleChange = event => {
    const file = event.target.files[0];
    if (file) {
      // Vérifier si la taille du fichier est inférieure à 10 Mo
      if (file.size > 10485760) {
        alert('Le fichier doit être inférieur à 10 Mo.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        onFileSelected(e.target.result, file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // Déterminer le type de fichier accepté
  const acceptTypes = accept === "Photo d'identité" ? "image/*" : "image/*,application/pdf";

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept={acceptTypes}  // Accepter les types de fichiers en fonction de la condition
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <button onClick={handleClick}>Upload {accept}</button>
    </div>
  );
};

export default FileInput;
