import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, Image, ScrollView, TouchableOpacity, ActivityIndicator, Alert, Platform, BackHandler } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useRoute, RouteProp, useNavigation, useFocusEffect } from '@react-navigation/native';
import { recupererListeVoyageurs } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import validerDemande from '../validerFormulaire';
import DocumentOrPhotoPicker from '../components/DocumentOrPhotoPicker';

type FormScreenRouteParams = {
  title: string,
  prixconsulat: number, 
  prixintervention: number,
  demandeId: number,
  url_flag: string,
  validite: string,
  informations: string
};

type RootStackParamList = {
  FormCambodge: FormScreenRouteParams;
  Demandes: {};
};

type FormScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Demandes'>;

const FormCambodge = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FormCambodge'>>();
  const navigation = useNavigation<FormScreenNavigationProp>();
  const demandeId = route.params?.demandeId;
  const isEditMode = demandeId != null;
  const [isLoading, setIsLoading] = useState(false);
  
  const [title, setTitle] = useState(route.params?.title);
  const [prixconsulat, setPrixconsulat] = useState(route.params?.prixconsulat);
  const [prixintervention, setPrixintervention] = useState(route.params?.prixintervention);
  const [urlFlag, setUrlFlag] = useState(route.params?.url_flag);
  const [validite, setValidite] = useState(route.params?.validite);
  const [informations, setInformations] = useState(route.params?.informations);
  const [posteArrivee, setPosteArrivee] = useState('');
  const [lettreInvitationUri, setLettreInvitationUri] = useState(null);



  const [dateDepart, setDateDepart] = useState('');
  const [dateSortie, setDateSortie] = useState('');
  const [numVisa, setNumVisa] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');
  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [assuranceVisa, setAssuranceVisa] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [sliderValue, setSliderValue] = useState(false);
  const [adresseHebergement, setAdresseHebergement] = useState('');
  const [codePostalHebergement, setCodePostalHebergement] = useState('');
  const [villeHebergement, setVilleHebergement] = useState('');
  const [villeArriveeCambodge, setVilleArriveeCambodge] = useState('');


  
  const optionsPosteArrivee = [
    { label: 'Sélectionnez', value: '' },
    { label: 'Aéroport International de Phnom Penh', value: 'phnom_penh' },
    { label: 'Aéroport International de Siem Reap', value: 'siem_reap' },
    { label: 'Aéroport International de Sihanoukville', value: 'sihanoukville' },
    { label: 'Poste frontière de Cham Yeam (Koh Kong) avec la Thaïlande', value: 'cham_yeam' },
    { label: 'Poste frontière de Poi Pet (Banteay Meanchey) avec la Thaïlande', value: 'poi_pet' },
    { label: 'Poste frontière de Bavet (Svay Rieng) avec le Vietnam', value: 'bavet' },
    { label: 'Poste frontière de Tropeang Kreal (Stung Treang) avec le Laos', value: 'tropeang_kreal' }
  ];
  

  const total = prixconsulat + prixintervention + (assuranceVisa === 'oui' ? 20 : 0);

  const validateForm = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remet l'heure de today à 00:00:00 pour comparer uniquement les dates
  
    const parseDate = (dateStr: string) => {
      const parts = dateStr.split('/');
      if (parts.length === 3) {
        return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`); // yyyy-mm-dd
      }
      return null;
    };
  
    const departDate = parseDate(dateDepart);
    const sortieDate = parseDate(dateSortie);
  
    const errors = [];
  
    // Vérifie si departDate est non null et valide avant de comparer
    if (departDate) {
      if (isNaN(departDate.getTime()) || departDate <= today) {
        errors.push("La date de départ doit être une date valide et postérieure à aujourd'hui.");
      }
    } else {
      errors.push("La date de départ est requise.");
    }
  
    // Vérifie si sortieDate est non null et valide avant de comparer à departDate
    if (dateSortie && sortieDate) {
      if (isNaN(sortieDate.getTime()) || sortieDate <= departDate!) {
        errors.push("La date de retour doit être une date valide et postérieure à la date de départ.");
      }
    } else if (dateSortie && !sortieDate) {
      errors.push("La date de retour est requise et doit être au format valide.");
    }
  
    // Validation des autres champs requis
    if (!selectedVoyageur) errors.push('Voyageur non sélectionné');
    if (!dateDepart.trim()) errors.push('Date de départ');
    if (!dateSortie.trim()) errors.push('Date de sortie');
    if (!posteArrivee) errors.push("Poste international d'arrivée");
  if (title.toLowerCase().includes('affaire') && !lettreInvitationUri) {
    errors.push("Lettre d'invitation");
  }
  if (!adresseHebergement.trim()) errors.push("Adresse de l'hébergement");
  if (!codePostalHebergement.trim()) errors.push("Code postal de l'hébergement");
  if (!villeHebergement.trim()) errors.push("Ville de l'hébergement");
  if (!villeArriveeCambodge.trim()) errors.push("Ville d'arrivée au Cambodge");
  
    if (!assuranceVisa.trim()) errors.push('Assurance Visa');
    if (!sliderValue) errors.push("Engagement sur les conditions générales de vente");
  
    console.log("Errors:", errors);
    
    setChampsNonRemplis(errors);
    setIsFormValid(errors.length === 0);
  };
  
  useEffect(() => {
    validateForm();
  }, [dateDepart, dateSortie, numVisa, assuranceVisa, sliderValue, selectedVoyageur]);
  

  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        
        // Modification pour la sélection automatique si un seul voyageur
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        } else {
          setSelectedVoyageur(''); // Réinitialise la sélection si plusieurs voyageurs
        }
      }
    };
  
    fetchVoyageurs();
  }, [demandeId]);
  

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);


  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?');
          if (confirmation) {
            navigation.goBack();
          }
        } else {
          Alert.alert(
            'Attention',
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?',
            [
              {
                text: 'Annuler',
                onPress: () => null,
                style: 'cancel',
              },
              {
                text: 'OK',
                onPress: () => navigation.goBack(),
              },
            ],
            { cancelable: false }
          );
        }
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', onBackPress);
      const unsubscribe = navigation.addListener('beforeRemove', (e) => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?');
          if (confirmation) {
            navigation.dispatch(e.data.action);
          }
        } else {
          e.preventDefault();
          Alert.alert(
            'Attention',
            'Les informations saisies ne sont pas enregistrées. Etes-vous sûr de quitter ce formulaire ?',
            [
              { text: 'Annuler', style: 'cancel', onPress: () => {} },
              { text: 'OK', style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
            ]
          );
        }
      });

      

      return () => {
        backHandler.remove();
        unsubscribe();
      };
    }, [navigation])
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = await AsyncStorage.getItem('@user');
     
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;
      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        assuranceVisa: assuranceVisa,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        posteArrivee: posteArrivee,
        lettreInvitationUri: lettreInvitationUri,
        adresseHebergement: adresseHebergement,
        codePostalHebergement: codePostalHebergement,
        villeHebergement: villeHebergement,
        villeArriveeCambodge: villeArriveeCambodge,
      };

      await validerDemande(data);
      console.log('Demande validée avec succès');
      setIsLoading(false);
      navigation.navigate('Demandes', '');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, ''); 
    const chars = numbers.split('');
    let formattedInput = '';
  
    chars.forEach((char, index) => {
      if (index === 2 || index === 4) {
        formattedInput += '/';
      }
      formattedInput += char;
    });
  
    return formattedInput.slice(0, 10); 
  };

  const disabledPressHandler = () => {};

  const backgroundColor = champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc';

  return (
    <KeyboardAwareScrollView style={styles.scrollView} extraHeight={250}>
      <View style={styles.container}>
        <Image source={{ uri: urlFlag }} style={styles.flag} />
        <Text style={styles.title}>Formulaire pour {title}</Text>
        <Text style={styles.libellest}>
          {validite}{"\n"}{informations}
        </Text>

        
        {!isEditMode && (
          <View>
           
            <View style={styles.pickerContainer}>
              <RNPickerSelect
                onValueChange={(value) => setSelectedVoyageur(value)}
                items={voyageurs.map((voyageur) => ({
                  label: `${voyageur.nom} ${voyageur.prenom}`,
                  value: voyageur.id.toString(),
                }))}
                placeholder={{
                  label: 'Sélectionner un voyageur',
                  value: null,
                }}
                style={pickerSelectStyles}
                value={selectedVoyageur}
                useNativeAndroidPickerStyle={false}
              />
            </View>
          </View>
        )}
        {selectedVoyageurObj && (
          <View style={styles.avatarContainer}>
            <Image
              source={{ uri: selectedVoyageurObj.url || 'https://example.com/default-avatar.png' }}
              style={styles.avatar}
            />
          </View>
        )}

        <View style={styles.inputContainer}>
          <Text>Date de départ de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de départ de votre voyage jj/mm/aaaa"
            value={dateDepart}
            onChangeText={(text) => setDateDepart(formatDate(text))}
          />
        </View>

        <View style={styles.inputContainer}>
          <Text>Date de retour de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de retour de votre voyage jj/mm/aaaa"
            value={dateSortie}
            onChangeText={(text) => setDateSortie(formatDate(text))}
          />
        </View>

        <Text>Poste international d'arrivée :</Text>
<RNPickerSelect
  onValueChange={(value) => setPosteArrivee(value)}
  items={optionsPosteArrivee}
  style={pickerSelectStyles}
  value={posteArrivee}
/>

{title.toLowerCase().includes('affaire') && (
  <DocumentOrPhotoPicker
    documentType="Lettre d'invitation"
    onDocumentSelected={(uri: any) => setLettreInvitationUri(uri)}
    modeOptions={['photo', 'selectPhoto', 'selectPdf']}
  />
)}

<View style={styles.inputContainer}>
  <Text>Adresse de l'hébergement :</Text>
  <TextInput
    style={styles.textInput}
    placeholder="Adresse de l'hébergement"
    value={adresseHebergement}
    onChangeText={setAdresseHebergement}
  />
</View>

<View style={styles.inputContainer}>
  <Text>Code postal de l'hébergement :</Text>
  <TextInput
    style={styles.textInput}
    placeholder="Code postal de l'hébergement"
    value={codePostalHebergement}
    onChangeText={setCodePostalHebergement}
   
  />
</View>

<View style={styles.inputContainer}>
  <Text>Ville de l'hébergement :</Text>
  <TextInput
    style={styles.textInput}
    placeholder="Ville de l'hébergement"
    value={villeHebergement}
    onChangeText={setVilleHebergement}
  />
</View>

<View style={styles.inputContainer}>
  <Text>Ville d'arrivée au Cambodge :</Text>
  <TextInput
    style={styles.textInput}
    placeholder="Ville d'arrivée au Cambodge"
    value={villeArriveeCambodge}
    onChangeText={setVilleArriveeCambodge}
  />
</View>

           

      <AssuranceVisaComponent 
        assuranceVisa={assuranceVisa} 
        setAssuranceVisa={setAssuranceVisa} 
        sliderValue={sliderValue} 
        setSliderValue={setSliderValue} 
      />

    <Text style={styles.libelle}>Frais de visa {prixconsulat}€ 
      </Text>
      <Text style={styles.libelle}> 
      Frais de service {prixintervention}€</Text>
        <Text style={styles.totalText}>
          Total : {total}€
        </Text>

        <View style={[styles.champsNonRemplisContainer, { backgroundColor }]}>
          {champsNonRemplis.length > 0 ? (
            <>
              <Text style={{ fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
        Formulaire incomplet.
      </Text>
              {champsNonRemplis.map((champ, index) => (
                <Text key={index}>{champ}</Text>
              ))}
            </>
          ) : (
            <Text>Formulaire complet</Text>
          )}
        </View>

        {isLoading ? (
          <ActivityIndicator size="large" color="#0000ff" />
        ) : (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={isFormValid ? handleSubmit : disabledPressHandler}
              style={[
                styles.button,
                { backgroundColor: isFormValid ? 'black' : '#A9A9A9' },
              ]}
              disabled={!isFormValid}
            >
              <Text style={styles.buttonText}>Valider ma demande</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
      </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  comboContainer: {
    marginTop: 20,  // Ajout d'un espace supplémentaire au-dessus
    marginBottom: 20,  // Ajout d'un espace supplémentaire en dessous
  },
  comboTitle: {
    
    marginBottom: 5,  // Espace sous le texte pour le séparer du picker
  },
  totalText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  radioButtonOption: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'grey',
    padding: 10,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
  },
  cameraContainer: {
    position: 'absolute',
    left: 0,
    width: 430,
    height: 750,
  },
  imagePickerButton: {
    backgroundColor: '#4e9af1',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: '80%',
    alignSelf: 'center',
  },
  imagePreview: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
  },
  champsNonRemplisContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInput: {
    marginBottom: 20,
    width: '100%',
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#d3d3d3',
  },
  inputContainer: {
    marginBottom: 15,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginTop: 5,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#eeeeee',
  },
  flag: {
    width: 50,
    height: 30,
    marginBottom: 10,
    alignSelf: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 3,
    textAlign: 'center',
  },
  libelle: {
    fontSize: 15,
    marginBottom: 2,
    textAlign: 'center',
  },
  libellest: {
    fontSize: 15,
    marginBottom: 20,
    textAlign: 'center',
  },
  pickerContainer: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 50,
  },
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
});

export default FormCambodge;
