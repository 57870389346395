import React, { useState } from 'react';
import {
  View, TextInput, StyleSheet, Text,
  ImageBackground, TouchableOpacity, Alert, Image, Platform
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { signUp } from '../Services'; // Vous devrez créer cette fonction dans vos services pour enregistrer l'utilisateur

const SignUpScreen = () => {
  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState(''); // Ajout de l'état pour le champ téléphone
  const [motDePasse, setMotDePasse] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState('red');
  const [passwordHelperText, setPasswordHelperText] = useState('Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.');
  const navigation = useNavigation();

  const isPasswordSecure = (password: any) => {
    return password.length >= 8;
  };

  const isEmailValid = (email: any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isPhoneValid = (phone: string) => {
    const phoneRegex = /^[0-9]+$/; // Accepte uniquement les chiffres, peu importe le nombre
    return phoneRegex.test(phone);
  };
  

  const getPasswordStrength = (password: any) => {
    const length = password.length;

    if (length >= 8) {
      setPasswordHelperText('Mot de passe fort');
      return 'green';
    }
    if (length >= 4) {
      setPasswordHelperText('Mot de passe moyen');
      return 'yellow';
    }
    setPasswordHelperText('Le mot de passe doit contenir au moins 8 caractères.');
    return 'red';
  };

  const handlePasswordChange = (password: any) => {
    setMotDePasse(password);
    setPasswordStrengthColor(getPasswordStrength(password));
  };

  const showAlert = (title: string, message: string) => {
    if (Platform.OS === 'web') {
      alert(`${title}: ${message}`);
    } else {
      Alert.alert(title, message);
    }
  };

  const handleSignUp = async () => {
    if (!nom.trim() || !email.trim() || !motDePasse.trim() || !telephone.trim()) {
      showAlert('Erreur', 'Veuillez remplir tous les champs.');
      return;
    }

    if (!isEmailValid(email)) {
      showAlert('Erreur', 'Veuillez entrer un email valide.');
      return;
    }

    if (!isPhoneValid(telephone)) {
      showAlert('Erreur', 'Veuillez entrer un numéro de téléphone valide.');
      return;
    }

    if (!isPasswordSecure(motDePasse)) {
      showAlert('Erreur', 'Le mot de passe doit contenir au moins 8 caractères.');
      return;
    }

    try {
      console.log(nom, email, motDePasse, telephone);
      await signUp({ nom, email, motDePasse, telephone });
      showAlert('Succès', 'Inscription réussie. Vous pouvez vous connecter.');
      navigation.goBack();
    } catch (error) {
      showAlert('Erreur', "L'inscription a échoué, il existe déjà un compte utilisateur avec ce mail.");
    }
  };

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <View style={styles.card}>
        <Text style={styles.title}>Création compte</Text>
        <TextInput
          style={styles.input}
          placeholder="Nom Prénom / Raison sociale"
          value={nom}
          onChangeText={setNom}
          placeholderTextColor="#aaa"
        />
        <TextInput
          style={styles.input}
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          autoCapitalize="none"
          placeholderTextColor="#aaa"
        />
        <TextInput
          style={styles.input}
          placeholder="Téléphone"
          value={telephone}
          onChangeText={setTelephone}
          keyboardType="phone-pad" // Spécifier un clavier adapté pour le champ téléphone
          placeholderTextColor="#aaa"
        />
        <View style={styles.passwordContainer}>
          <TextInput
            style={styles.inputPassword}
            placeholder="Mot de passe"
            value={motDePasse}
            onChangeText={handlePasswordChange}
            secureTextEntry={!passwordVisible}
            placeholderTextColor="#aaa"
          />
          <TouchableOpacity onPress={() => setPasswordVisible(!passwordVisible)}>
            <Image
              source={passwordVisible ? require('../assets/eye-off.png') : require('../assets/eye.png')}
              style={styles.eyeIcon}
            />
          </TouchableOpacity>
        </View>
        <View style={[styles.passwordStrengthIndicator, { backgroundColor: passwordStrengthColor }]} />
        <Text style={styles.passwordHelperText}>{passwordHelperText}</Text>
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={handleSignUp}>
            <Text style={styles.buttonText}>Valider</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={[styles.buttonContainer, { backgroundColor: 'gray', marginTop: 10 }]}
        >
          <Text style={styles.buttonText}>Revenir en arrière</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '90%',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    elevation: 5,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    marginBottom: 10,
    color: '#000',
  },
  inputPassword: {
    flex: 1,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    color: '#000',
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  eyeIcon: {
    width: 25,
    height: 25,
    marginLeft: -35,
  },
  passwordStrengthIndicator: {
    width: '50%',
    height: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  passwordHelperText: {
    fontSize: 12,
    color: 'gray',
    marginBottom: 10,
  },
  buttonContainer: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default SignUpScreen;
