// components/TestFileSystem.js
import React, { useState, useEffect } from 'react';
import { View, Text, Button, FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { Linking } from 'react-native';
import PDFReader from 'rn-pdf-reader-js';

const TestFileSystem = () => {
  const [fileContent, setFileContent] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const fileUri = `${FileSystem.documentDirectory}example.txt`;

  useEffect(() => {
    // Lire le fichier au démarrage de l'application
    readFile();
  }, []);

  const writeFile = async () => {
    try {
      await FileSystem.writeAsStringAsync(fileUri, 'Hello, world!');
      alert('Fichier écrit avec succès!');
      readFile(); // Lire le fichier après l'avoir écrit pour mettre à jour le contenu affiché
    } catch (error) {
      console.error('Erreur lors de l\'écriture du fichier', error);
    }
  };

  const readFile = async () => {
    try {
      const content = await FileSystem.readAsStringAsync(fileUri);
      setFileContent(content);
    } catch (error) {
      console.error('Erreur lors de la lecture du fichier', error);
    }
  };

  const readDirectory = async () => {
    try {
      const directory = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory);
      const fileDetails = await Promise.all(
        directory.map(async (file) => {
          const info = await FileSystem.getInfoAsync(`${FileSystem.documentDirectory}${file}`);
          return { name: file, size: info.size };
        })
      );
      setFiles(fileDetails);
    } catch (error) {
      console.error('Erreur lors de la lecture du répertoire', error);
    }
  };

  const openFile = (fileName) => {
    const fileUri = `${FileSystem.documentDirectory}${fileName}`;
    if (fileName.endsWith('.pdf')) {
      setSelectedPdf(fileUri);
    } else {
      Linking.openURL(fileUri);
    }
  };

  return (
    <View style={styles.container}>
      {selectedPdf ? (
        <PDFReader
          source={{ uri: selectedPdf }}
          style={{ flex: 1 }}
        />
      ) : (
        <>
          <Text style={styles.text}>Contenu du fichier: {fileContent}</Text>
          <Button title="Écrire dans le fichier" onPress={writeFile} />
          <View style={styles.spacer} />
          <Button title="Lire le fichier" onPress={readFile} />
          <View style={styles.spacer} />
          <Button title="Afficher les fichiers" onPress={readDirectory} />
          <FlatList
            data={files}
            keyExtractor={(item) => item.name}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => openFile(item.name)}>
                <Text style={styles.fileItem}>{item.name} ({(item.size / 1024).toFixed(2)} KB)</Text>
              </TouchableOpacity>
            )}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    flex: 1,
  },
  text: {
    marginBottom: 10,
  },
  spacer: {
    height: 10,
  },
  fileItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
});

export default TestFileSystem;
