import React, { useState } from 'react';
import { ScrollView, Modal, Button, View, Text, TouchableOpacity, StyleSheet, Linking, Switch } from 'react-native';




const AssuranceVisaComponent = ({ assuranceVisa, setAssuranceVisa, sliderValue, setSliderValue }) => {

  const [modalVisible, setModalVisible] = useState(false);

  return (


    
    <View style={styles.container}>
      <Modal
  animationType="slide"
  transparent={true}
  visible={modalVisible}
  onRequestClose={() => setModalVisible(false)}
>
  <View style={styles.modalContainer}>
    <View style={styles.modalView}>
      <Text style={styles.modalTitle}>Conditions générales de vente</Text>
      <ScrollView style={styles.modalScroll}>
      <Text style={styles.modalText}>
  Formulaire fourni par Visa Monde et nécessairement rempli et retourné à Visa Monde par le Client lors de sa demande de Prestation. 
  Ce formulaire peut être un Bon de Commande Visa si le Client a recours à Visa Monde pour une demande de visa, ou un Bon de Commande Légalisation, 
  si le Client a recours à Visa Monde pour une demande de légalisation d’un document. 
  
  L’envoi du Bon de commande par le Client à Visa Monde vaut acceptation de l’offre de Prestation et engagement de la payer.
  
  {"\n\n"}Dans tous les cas, le Client devra nécessairement remplir et signer un formulaire nommé Bon de Commande Visa en cas de demande de visa 
  et un Bon de Commande Légalisation en cas de demande de légalisation. 
  
  Ces deux documents sont désignés ci-après sous la dénomination unique « Bon de Commande ». 
  {"\n\n"}Le Bon de Commande devra être remis à Visa Monde soit directement au guichet (lorsque la commande est faite au guichet), 
  soit par courrier postal ou pli déposé par porteur. 

  {"\n\n"}Aucune demande de Prestation ne sera traitée sans remise de Bon de Commande intégralement rempli et dûment signé. 
  Les informations renseignées par le Client sur le Bon de Commande déterminent la Prestation sollicitée par le Client 
  et conditionnent la demande de visa ou de légalisation de documents que transmettra Visa Monde auprès des Autorités Compétentes.

  {"\n\n"}Visa Monde constitue le dossier du Client uniquement au regard des informations reportées sur le Bon de Commande. 
  En aucun cas, Visa Monde ne vérifie l’exactitude des informations reportées sur le Bon de Commande, quand bien même d’autres documents 
  fournis par le Client viendraient les contredire. 

  {"\n\n"}Il appartient donc au Client de fournir des informations sincères, exactes et complètes. 
  En cas de demande de visa, il doit notamment indiquer la date d’entrée et de sortie de l’ensemble des Etats qu’il traversera au cours de son voyage, 
  y compris les Etats dans lesquels il ne ferait que transiter. 
  
  Le Client doit s’assurer que l’identité renseignée sur le Bon de Commande est conforme à celle de son ou ses documents de voyage. 
  
  {"\n\n"}En cas de demande de légalisation de documents, le Client doit renseigner le Bon de commande et la date limite 
  à laquelle il souhaite recevoir les documents légalisés. 

  {"\n\n"}La remise du Bon de commande par le Client à Visa Monde vaut acceptation de l’offre de Prestation, du tarif de la Prestation et des présentes conditions générales de ventes. 
  
  {"\n\n"}Le Client s’engage à communiquer des coordonnées à jour et à informer Visa Monde sans délai de tout changement survenu depuis l’envoi du Bon de Commande.
</Text>

      </ScrollView>
      <Button title="Fermer" onPress={() => setModalVisible(false)} />
    </View>
  </View>
</Modal>

      <Text style={styles.title}>Assurance Visa (20€)</Text>

      <Text style={styles.smallText}>
    Garantie de remboursement en cas de non obtention du visa, de retard de délivrance… (voir tableau des garanties)
  </Text>
      
      <View style={styles.radioButtonContainer}>
        <TouchableOpacity 
          style={[styles.radioButtonOption, assuranceVisa === 'oui' && styles.selectedOption]}
          onPress={() => {
            setAssuranceVisa('oui');
            
          }}
        >
          <Text style={[styles.radioButtonText, assuranceVisa === 'oui' && styles.selectedText]}>Oui</Text>
        </TouchableOpacity>
        <TouchableOpacity 
          style={[styles.radioButtonOption, assuranceVisa === 'non' && styles.selectedOption]}
          onPress={() => {
            setAssuranceVisa('non');
            setSliderValue(false); // Désactiver le slider si "Non" est sélectionné
          }}
        >
          <Text style={[styles.radioButtonText, assuranceVisa === 'non' && styles.selectedText]}>Non</Text>
        </TouchableOpacity>
      </View>

      
      
        
      

      <View style={styles.linksContainer}>

      <TouchableOpacity onPress={() => Linking.openURL('https://visamonde.com/chapka/Tableau%20des%20garanties%20assurance%20visa.pdf')}>
          <Text style={styles.linkText}>Voir tableau des garanties</Text>
        </TouchableOpacity>


        <TouchableOpacity onPress={() => Linking.openURL('https://visamonde.com/chapka/CGV%20ass%20visa.pdf')}>
          <Text style={styles.linkText}>Voir les conditions générales</Text>
        </TouchableOpacity>

       
        <TouchableOpacity onPress={() => Linking.openURL('https://visamonde.com/chapka/IPID%20assurance%20visa.pdf')}>
          <Text style={styles.linkText}>Voir l’IPID</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.switchContainer}>
  <Text style={styles.switchText}>
    En sélectionnant oui, j'accepte les  
    <TouchableOpacity onPress={() => setModalVisible(true)}>
  <Text style={styles.linkText}>conditions générales de ventes</Text>
</TouchableOpacity>

  </Text>
  <View style={styles.switchRow}>
    <Switch
      value={sliderValue}
      onValueChange={(value) => setSliderValue(value)}
      thumbColor={sliderValue ? '#3eab36' : '#f4f3f4'}
      trackColor={{ false: '#767577', true: '#767577' }}
    />
    <Text style={styles.labelText}>Oui</Text>
  </View>
</View>


    </View>
  );
};

const styles = StyleSheet.create({
  smallText: {
    fontSize: 12, // Petite taille de police
    color: 'gray', // Couleur de texte grise
    textAlign: 'center', // Centré sous le titre
    marginBottom: 10, // Espace en dessous
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond semi-transparent
  },
  modalView: {
    width: '90%',
    height: '70%',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontSize: 16,
    textAlign: 'justify',
  },
  modalScroll: {
    marginBottom: 20,
  },
  


  container: {
    marginBottom: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  radioButtonOption: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'grey',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  selectedOption: {
    backgroundColor: '#3eab36',
  },
  radioButtonText: {
    fontSize: 16,
  },
  selectedText: {
    color: 'white',
  },
  switchContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  switchText: {
    marginBottom: 10,
    fontSize: 16,
    textAlign: 'center',
  },
  labelText: {
    fontSize: 16,
    marginLeft: 10, // Espace entre le switch et le texte "Oui"
  },
  linksContainer: {
    marginTop: 15,
    alignItems: 'center',
  },
  linkText: {
    color: '#0645AD',
    fontSize: 16,
    textDecorationLine: 'underline',
    marginVertical: 5,
  },
});

export default AssuranceVisaComponent;
