import React, { useCallback, useEffect, useState } from 'react';
import { Alert, View, Text, StyleSheet, Image, ImageBackground, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { recupererListeVoyageurs, supprimerVoyageurService } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import Icon from 'react-native-vector-icons/MaterialIcons'; // Assurez-vous d'importer Icon depuis react-native-vector-icons
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from '../components/Header';


type RootStackParamList = {
  Formulaire_creation_voyageur: { id: number };
  VoyageurUpdate: { voyageur: Voyageurs };
};

type VoyageursScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Formulaire_creation_voyageur', 'VoyageurUpdate'>;

const VoyageursScreen = () => {
  const navigation = useNavigation<VoyageursScreenNavigationProp>();
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);

  // Fonction pour rafraîchir la liste des voyageurs
  const fetchVoyageurs = async () => {
    const userJson = await AsyncStorage.getItem('@user');
    if (userJson !== null) {
      const user = JSON.parse(userJson);
      const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
      const updatedVoyageurs = listeVoyageurs.map(voyageur => {
        return {
          ...voyageur,
          url: voyageur.url ? `${voyageur.url}?time=${Date.now()}` : ''
        };
      });
      setVoyageurs(updatedVoyageurs);
    } 
   
  };

  useEffect(() => {
    fetchVoyageurs();
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetchVoyageurs();
    }, [])
  );

  const isVoyageurListEmpty = voyageurs.length === 0; // Vérifier si la liste des voyageurs est vide


  
  const supprimerVoyageur = async (id: number, nom: string, prenom: string) => {
    const message = `Êtes-vous sûr de vouloir supprimer le voyageur ${nom} ${prenom} ? 
    Toute suppression entraînera la suppression des demandes et des visas liés à ce voyageur.`;
  
    if (Platform.OS === 'web') {
      // Utilisation de window.confirm pour le web
      if (window.confirm(message)) {
        console.log(`Suppression du voyageur avec l'ID : ${id}`);
        try {
          await supprimerVoyageurService(id);
          fetchVoyageurs(); // Recharger la liste après la suppression
        } catch (error) {
          console.error("Erreur lors de la suppression du voyageur", error);
          // Afficher un message d'erreur si nécessaire
        }
      } else {
        console.log("Suppression annulée");
      }
    } else {
      // Utilisation de Alert.alert pour les plateformes mobiles
      Alert.alert(
        "Confirmer la suppression",
        message,
        [
          {
            text: "Annuler",
            onPress: () => console.log("Suppression annulée"),
            style: "cancel"
          },
          { text: "Oui", onPress: async () => {
              console.log(`Suppression du voyageur avec l'ID : ${id}`);
              try {
                await supprimerVoyageurService(id);
                fetchVoyageurs(); // Recharger la liste après la suppression
              } catch (error) {
                console.error("Erreur lors de la suppression du voyageur", error);
                // Afficher un message d'erreur si nécessaire
              }
            }
          }
        ]
      );
    }
  };
  
  
  

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <Header /> 
      {isVoyageurListEmpty && (
        <Text style={styles.emptyText}>Aucun voyageur trouvé.</Text>
      )} 
      <TouchableOpacity 
        style={styles.fullWidthFooterCard} 
        onPress={() => navigation.navigate('Formulaire_creation_voyageur', {id: 1})}
      >
        <Icon name="add" size={50} color="#000" />
        <Text style={styles.footerText}>Créer un voyageur</Text>
      </TouchableOpacity>
      <ScrollView contentContainerStyle={[styles.grid, isVoyageurListEmpty ? styles.centerContent : {}]}>
        {voyageurs.map((item, index) => (
          <TouchableOpacity 
            key={index}
            onPress={() => navigation.navigate('VoyageurUpdate', { voyageur: item })}
            style={voyageurs.length === 1 ? styles.cardTouchable : styles.cardTouchableMultiple} // Appliquez le style en fonction du nombre de voyageurs
          >
            <View style={styles.card}>
            <Text style={styles.name}>{item.prenom}</Text>
              <Text style={styles.name}>{item.nom}</Text>
              
              <Image source={{ uri: item.url || './src/assets/avatar.png' }} style={styles.avatar} />
              <TouchableOpacity onPress={() => supprimerVoyageur(item.id, item.nom, item.prenom)} style={styles.deleteIcon}>
                <Icon name="delete" size={24} color="#FF0000" />
              </TouchableOpacity>
              <Text style={styles.prenom}>{item.numeropax}</Text>
            </View>
          </TouchableOpacity>
        ))}
        
      </ScrollView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  
  centerContent: {
    justifyContent: 'center', // Centrer le contenu si la liste est vide
    alignItems: 'center',
  },
   fullWidthFooterCard: {
    width: '90%', // Le bouton prend 90% de la largeur de l'écran
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 20, // Espace vertical pour le bouton
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    borderRadius: 10, // Bords arrondis
    alignSelf: 'center', // Centre le bouton horizontalement
    marginTop: 10, // Espace au-dessus du bouton
    marginBottom: 10, // Espace en-dessous du bouton
  },
  emptyText: {
    textAlign: 'center',
    fontSize: 16,
    color: 'white',
    marginTop: 20,
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  cardTouchable: {
    width: '65%', // Largeur par défaut pour une seule carte
    margin: 10,
  },
  cardTouchableMultiple: {
    width: '45%', // Réduire la largeur pour plusieurs cartes
    margin: 10,
  },
    deleteIcon: {
        position: 'absolute', // Positionne l'icône par rapport à la carte
        bottom: 10, // Distance du bas de la carte
        right: 10, // Distance du côté droit de la carte
      },
    footerCard: {
        flex: 1,
        margin: 10,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#ddd',
        borderRadius: 10,
        padding: 20,
        backgroundColor: '#fff',
      },
      footerText: {
        marginTop: 10,
        fontSize: 16,
        fontWeight: 'bold',
      },  
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
 
  card: {
    flex: 1,
    margin: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#fff',
    alignItems: 'center', // Centre tous les éléments dans la carte
  },
  name: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center', // Centre le texte horizontalement
  },
  prenom: {
    fontSize: 14,
    textAlign: 'center', // Centre le texte horizontalement
    marginBottom: 10, // Espace entre le prénom et l'avatar
  },
  avatar: {
    width: 80, // Taille de l'avatar ajustée
    height: 80,
    borderRadius: 40, // Rend l'avatar complètement rond
  },
});

export default VoyageursScreen;
