import React, { useState } from 'react';
import {
  View, TextInput, StyleSheet, Text,
  ImageBackground, TouchableOpacity, Alert
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { sendPasswordResetEmail } from '../Services'; // Fonction à créer pour envoyer l'email

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState('');
  const navigation = useNavigation();

  const isEmailValid = (email:any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlePasswordReset = async () => {
    if (!email.trim()) {
      Alert.alert("Erreur", "Veuillez entrer votre adresse e-mail.");
      return;
    }

    if (!isEmailValid(email)) {
      Alert.alert("Erreur", "Veuillez entrer une adresse e-mail valide.");
      return;
    }

    try {
      console.log(email);
      await sendPasswordResetEmail(email);
      Alert.alert("Succès", "Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse.");
      navigation.goBack();
    } catch (error) {
      Alert.alert("Erreur", "La réinitialisation du mot de passe a échoué.");
    }
  };

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <View style={styles.card}>
        <Text style={styles.title}>Réinitialisation du mot de passe</Text>
        <TextInput
          style={styles.input}
          placeholder="Entrez votre email"
          value={email}
          onChangeText={setEmail}
          autoCapitalize="none"
        />
        <View style={styles.buttonContainer}>
          <TouchableOpacity onPress={handlePasswordReset}>
            <Text style={styles.buttonText}>Envoyer l'email</Text>
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={[styles.buttonContainer, { backgroundColor: 'gray', marginTop: 10 }]}
        >
          <Text style={styles.buttonText}>Annuler</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '90%',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    elevation: 5,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    marginBottom: 10,
  },
  buttonContainer: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
    width: '100%',
    marginTop: 20,
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default ForgotPasswordScreen;
