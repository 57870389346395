import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from './screens/LoginScreen';
import MyTabs from './components/MyTabs';
import ParameterScreen from './screens/ParameterScreen';
import FormScreen from './screens/FormScreen';
import AdresseFacturation from './components/AdresseFacturation';
import VoyageurInsert from './components/VoyageurInsert';
import VoyageurUpdate from './components/VoyageurUpdate';
import FormTemplate from './screens/FormTemplate';
import SignUpScreen from './components/SignUpScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen ';

import FormUSA from './Formulaires/FormUSA';
import FormAngola from './Formulaires/FormAngola';
import FormArabieSaoudite from './Formulaires/FormArabieSaoudite';
import FormAustralie from './Formulaires/FormAustralie';
import FormAzerbaidjan from './Formulaires/FormAzerbaidjan';
import FormBahrein from './Formulaires/FormBahrein';
import FormBenin from './Formulaires/FormBenin';
import FormBurkinaFaso from './Formulaires/FormBurkinaFaso';
import FormCambodge from './Formulaires/FormCambodge';
import FormCameroun from './Formulaires/FormCameroun';
import FormCanada from './Formulaires/FormCanada';
import FormCapVert from './Formulaires/FormCapVert';
import FormCoreeDuSud from './Formulaires/FormCoreeDuSud';
import FormCoteIvoire from './Formulaires/FormCoteIvoire';
import FormCuba from './Formulaires/FormCuba';
import FormDjibouti from './Formulaires/FormDjibouti';
import FormDubai from './Formulaires/FormDubai';
import FormEgypte from './Formulaires/FormEgypte';
import FormEthiopie from './Formulaires/FormEthiopie';
import FormGabon from './Formulaires/FormGabon';
import FormGeorgie from './Formulaires/FormGeorgie';
import FormGuinee from './Formulaires/FormGuinee';
import FormInde from './Formulaires/FormInde';
import FormIndonesie from './Formulaires/FormIndonesie';
import FormJordanie from './Formulaires/FormJordanie';
import FormKenya from './Formulaires/FormKenya';
import FormKirghizistan from './Formulaires/FormKirghizistan';
import FormKoweit from './Formulaires/FormKoweit';
import FormLaos from './Formulaires/FormLaos';
import FormLesotho from './Formulaires/FormLesotho';
import FormMadagascar from './Formulaires/FormMadagascar';
import FormMalawi from './Formulaires/FormMalawi';
import FormMongolie from './Formulaires/FormMongolie';
import FormMozambique from './Formulaires/FormMozambique';
import FormMyanmar from './Formulaires/FormMyanmar';
import FormNouvelleZelande from './Formulaires/FormNouvelleZelande';
import FormOman from './Formulaires/FormOman';
import FormOuganda from './Formulaires/FormOuganda';
import FormOuzbekistan from './Formulaires/FormOuzbekistan';
import FormPakistan from './Formulaires/FormPakistan';
import FormPapouasieNG from './Formulaires/FormPapouasieNG';
import FormRepubliqueDominicaine from './Formulaires/FormRepubliqueDominicaine';
import FormSeychelles from './Formulaires/FormSeychelles';
import FormSierraLeone from './Formulaires/FormSierraLeone';
import FormSingapour from './Formulaires/FormSingapour';
import FormSriLanka from './Formulaires/FormSriLanka';
import FormTadjikistan from './Formulaires/FormTadjikistan';
import FormTanzanie from './Formulaires/FormTanzanie';
import FormThailande from './Formulaires/FormThailande';
import FormVietnam from './Formulaires/FormVietnam';
import FormZambie from './Formulaires/FormZambie';
import FormZimbabwe from './Formulaires/FormZimbabwe';
import TestFileSystem from './components/TestFileSystem';
import VisasScreen from './screens/VisasScreen';

type RootStackParamList = { Paiement: { bonId: number; urlPaiement: string; }; Login: {}; SignUp: {}; ForgotPassword: {}; Retour: {}; Formulaire_Template: {}; Paramètres: {}; VoyageurInsert: {}; VoyageurUpdate: {}; Formulaire_creation_voyageur: {}; Formulaire_USA: {}; Formulaire_Angola: {}; Formulaire_Arabie_Saoudite: {}; Formulaire_Australie: {}; Formulaire_Azerbaidjan: {}; Formulaire_Bahrein: {}; Formulaire_Benin: {}; Formulaire_Burkina_Faso: {}; Formulaire_Cambodge: {}; Formulaire_Cameroun: {}; Formulaire_Canada: {}; Formulaire_Cap_Vert: {}; Formulaire_Coree_Du_Sud: {}; Formulaire_Cote_d_ivoire: {}; Formulaire_Cuba: {}; Formulaire_Djibouti: {}; Formulaire_Dubai: {}; Formulaire_Egypte: {}; Formulaire_Ethiopie: {}; Formulaire_Gabon: {}; Formulaire_Georgie: {}; Formulaire_Guinee: {}; Formulaire_Inde: {}; Formulaire_Indonesie: {}; Formulaire_Jordanie: {}; Formulaire_Kenya: {}; Formulaire_Kirghizistan: {}; Formulaire_Koweit: {}; Formulaire_Laos: {}; Formulaire_Lesotho: {}; Formulaire_Madagascar: {}; Formulaire_Malawi: {};
Formulaire_Mongolie: {}; Formulaire_Mozambique: {}; Formulaire_Myanmar: {}; Formulaire_Nouvelle_Zelande: {};
Formulaire_Oman: {}; Formulaire_Ouganda: {}; Formulaire_Ouzbekistan: {}; Formulaire_Pakistan: {};Formulaire_PapouasieNG: {};
Formulaire_Republique_Dominicaine: {};Formulaire_Seychelles: {};Formulaire_Sierra_Leone: {};
Formulaire_Singapour: {};Formulaire_Sri_Lanka: {};Formulaire_Tadjikistan: {};Formulaire_Tanzanie: {};
Formulaire_Thailande: {};Formulaire_Vietnam: {};Formulaire_Zambie: {};Formulaire_Zimbabwe: {};
TestFileSystem: {}; VisasScreen: {};};


const Stack = createStackNavigator<RootStackParamList>();

function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator
  screenOptions={{
    gestureEnabled: false, // Désactiver les gestes de retour pour tous les écrans
    headerBackTitle: 'Retour', // Définir le texte de retour
    headerBackTitleStyle: { color: 'green' }, // Définir la couleur du texte de retour
    headerTintColor: 'green', // Couleur de la flèche "Retour"
   }} // Désactiver les gestes de retour pour tous les écrans
>
      <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
      <Stack.Screen name="TestFileSystem" component={TestFileSystem} options={{ presentation: 'modal', title: 'Test File System' }} />

     
      <Stack.Screen name="SignUp" component={SignUpScreen} options={{ headerShown: false }} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ headerShown: false }} />

      <Stack.Screen name="Retour" component={MyTabs} options={{ headerShown: false }} />

      <Stack.Screen name="Paramètres" component={ParameterScreen} options={{ presentation: 'modal' }} />
      <Stack.Screen name="Paiement" component={AdresseFacturation} options={{ presentation: 'modal' }} />
      <Stack.Screen name="Formulaire_creation_voyageur" component={VoyageurInsert} options={{ presentation: 'modal' , title: 'Nouveau Voyageur' }} />
      
      

      
      <Stack.Screen name="VoyageurUpdate" component={VoyageurUpdate} options={{ presentation: 'modal' , title: 'Modifier Voyageur' }} />

      <Stack.Screen name="Formulaire_Template" component={FormTemplate} options={{ presentation: 'modal' }} />
      
      
      <Stack.Screen name="Formulaire_Djibouti" component={FormDjibouti} options={{ presentation: 'modal', title: 'Formulaire Djibouti' }} />
<Stack.Screen name="Formulaire_Cuba" component={FormCuba} options={{ presentation: 'modal', title: 'Formulaire Cuba' }} />
<Stack.Screen name="Formulaire_Cote_d_ivoire" component={FormCoteIvoire} options={{ presentation: 'modal', title: 'Formulaire Côte d\'Ivoire' }} />
<Stack.Screen name="Formulaire_Coree_Du_Sud" component={FormCoreeDuSud} options={{ presentation: 'modal', title: 'Formulaire Corée du Sud' }} />
<Stack.Screen name="Formulaire_Cameroun" component={FormCameroun} options={{ presentation: 'modal', title: 'Formulaire Cameroun' }} />
<Stack.Screen name="Formulaire_Cambodge" component={FormCambodge} options={{ presentation: 'modal', title: 'Formulaire Cambodge' }} />
<Stack.Screen name="Formulaire_USA" component={FormUSA} options={{ presentation: 'modal', title: 'Formulaire USA' }} />
<Stack.Screen name="Formulaire_Angola" component={FormAngola} options={{ presentation: 'modal', title: 'Formulaire Angola' }} />
<Stack.Screen name="Formulaire_Arabie_Saoudite" component={FormArabieSaoudite} options={{ presentation: 'modal', title: 'Formulaire Arabie Saoudite' }} />
<Stack.Screen name="Formulaire_Australie" component={FormAustralie} options={{ presentation: 'modal', title: 'Formulaire Australie' }} />
<Stack.Screen name="Formulaire_Azerbaidjan" component={FormAzerbaidjan} options={{ presentation: 'modal', title: 'Formulaire Azerbaïdjan' }} />
<Stack.Screen name="Formulaire_Bahrein" component={FormBahrein} options={{ presentation: 'modal', title: 'Formulaire Bahreïn' }} />
<Stack.Screen name="Formulaire_Benin" component={FormBenin} options={{ presentation: 'modal', title: 'Formulaire Bénin' }} />
<Stack.Screen name="Formulaire_Burkina_Faso" component={FormBurkinaFaso} options={{ presentation: 'modal', title: 'Formulaire Burkina Faso' }} />
<Stack.Screen name="Formulaire_Canada" component={FormCanada} options={{ presentation: 'modal', title: 'Formulaire Canada' }} />
<Stack.Screen name="Formulaire_Cap_Vert" component={FormCapVert} options={{ presentation: 'modal', title: 'Formulaire Cap-Vert' }} />
<Stack.Screen name="Formulaire_Dubai" component={FormDubai} options={{ presentation: 'modal', title: 'Formulaire Dubaï' }} />
<Stack.Screen name="Formulaire_Egypte" component={FormEgypte} options={{ presentation: 'modal', title: 'Formulaire Égypte' }} />
<Stack.Screen name="Formulaire_Ethiopie" component={FormEthiopie} options={{ presentation: 'modal', title: 'Formulaire Éthiopie' }} />
<Stack.Screen name="Formulaire_Gabon" component={FormGabon} options={{ presentation: 'modal', title: 'Formulaire Gabon' }} />
<Stack.Screen name="Formulaire_Georgie" component={FormGeorgie} options={{ presentation: 'modal', title: 'Formulaire Géorgie' }} />
<Stack.Screen name="Formulaire_Guinee" component={FormGuinee} options={{ presentation: 'modal', title: 'Formulaire Guinée' }} />
<Stack.Screen name="Formulaire_Inde" component={FormInde} options={{ presentation: 'modal', title: 'Formulaire Inde' }} />
<Stack.Screen name="Formulaire_Indonesie" component={FormIndonesie} options={{ presentation: 'modal', title: 'Formulaire Indonésie' }} />
<Stack.Screen name="Formulaire_Jordanie" component={FormJordanie} options={{ presentation: 'modal', title: 'Formulaire Jordanie' }} />
<Stack.Screen name="Formulaire_Kenya" component={FormKenya} options={{ presentation: 'modal', title: 'Formulaire Kenya' }} />
<Stack.Screen name="Formulaire_Kirghizistan" component={FormKirghizistan} options={{ presentation: 'modal', title: 'Formulaire Kirghizistan' }} />
<Stack.Screen name="Formulaire_Koweit" component={FormKoweit} options={{ presentation: 'modal', title: 'Formulaire Koweït' }} />
<Stack.Screen name="Formulaire_Laos" component={FormLaos} options={{ presentation: 'modal', title: 'Formulaire Laos' }} />
<Stack.Screen name="Formulaire_Lesotho" component={FormLesotho} options={{ presentation: 'modal', title: 'Formulaire Lesotho' }} />
<Stack.Screen name="Formulaire_Madagascar" component={FormMadagascar} options={{ presentation: 'modal', title: 'Formulaire Madagascar' }} />
<Stack.Screen name="Formulaire_Malawi" component={FormMalawi} options={{ presentation: 'modal', title: 'Formulaire Malawi' }} />
<Stack.Screen name="Formulaire_Mongolie" component={FormMongolie} options={{ presentation: 'modal', title: 'Formulaire Mongolie' }} />
<Stack.Screen name="Formulaire_Mozambique" component={FormMozambique} options={{ presentation: 'modal', title: 'Formulaire Mozambique' }} />
<Stack.Screen name="Formulaire_Myanmar" component={FormMyanmar} options={{ presentation: 'modal', title: 'Formulaire Myanmar' }} />
<Stack.Screen name="Formulaire_Nouvelle_Zelande" component={FormNouvelleZelande} options={{ presentation: 'modal', title: 'Formulaire Nouvelle-Zélande' }} />
<Stack.Screen name="Formulaire_Oman" component={FormOman} options={{ presentation: 'modal', title: 'Formulaire Oman' }} />
<Stack.Screen name="Formulaire_Ouganda" component={FormOuganda} options={{ presentation: 'modal', title: 'Formulaire Ouganda' }} />
<Stack.Screen name="Formulaire_Ouzbekistan" component={FormOuzbekistan} options={{ presentation: 'modal', title: 'Formulaire Ouzbékistan' }} />
<Stack.Screen name="Formulaire_Pakistan" component={FormPakistan} options={{ presentation: 'modal', title: 'Formulaire Pakistan' }} />
<Stack.Screen name="Formulaire_PapouasieNG" component={FormPapouasieNG} options={{ presentation: 'modal', title: 'Formulaire Papouasie-Nouvelle-Guinée' }} />
<Stack.Screen name="Formulaire_Republique_Dominicaine" component={FormRepubliqueDominicaine} options={{ presentation: 'modal', title: 'Formulaire République Dominicaine' }} />
<Stack.Screen name="Formulaire_Seychelles" component={FormSeychelles} options={{ presentation: 'modal', title: 'Formulaire Seychelles' }} />
<Stack.Screen name="Formulaire_Sierra_Leone" component={FormSierraLeone} options={{ presentation: 'modal', title: 'Formulaire Sierra Leone' }} />
<Stack.Screen name="Formulaire_Singapour" component={FormSingapour} options={{ presentation: 'modal', title: 'Formulaire Singapour' }} />
<Stack.Screen name="Formulaire_Sri_Lanka" component={FormSriLanka} options={{ presentation: 'modal', title: 'Formulaire Sri Lanka' }} />
<Stack.Screen name="Formulaire_Tadjikistan" component={FormTadjikistan} options={{ presentation: 'modal', title: 'Formulaire Tadjikistan' }} />
<Stack.Screen name="Formulaire_Tanzanie" component={FormTanzanie} options={{ presentation: 'modal', title: 'Formulaire Tanzanie' }} />
<Stack.Screen name="Formulaire_Thailande" component={FormThailande} options={{ presentation: 'modal', title: 'Formulaire Thaïlande' }} />
<Stack.Screen name="Formulaire_Vietnam" component={FormVietnam} options={{ presentation: 'modal', title: 'Formulaire Vietnam' }} />
<Stack.Screen name="Formulaire_Zambie" component={FormZambie} options={{ presentation: 'modal', title: 'Formulaire Zambie' }} />
<Stack.Screen name="Formulaire_Zimbabwe" component={FormZimbabwe} options={{ presentation: 'modal', title: 'Formulaire Zimbabwe' }} />


      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
