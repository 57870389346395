import React from 'react';
import { View, Text, Image, Modal, TouchableOpacity, StyleSheet, Platform } from 'react-native';
const Swiper = Platform.OS === 'web' ? require('react-slick').default : require('react-native-swiper').default;

interface CarouselItem {
  url: string;
}

interface ModalMobileProps {
  isVisible: boolean;
  onClose: () => void;
  items: CarouselItem[];
}

const ModalMobile: React.FC<ModalMobileProps> = ({ isVisible, onClose, items }) => {
  const renderCarouselItem = (item: CarouselItem, index: number) => (
    <View key={index} style={styles.carouselItem}>
      <Image source={{ uri: item.url }} style={styles.carouselImage} />
    </View>
  );

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Tuto</Text>
          <Swiper
            style={styles.wrapper}
            showsButtons={true}
            loop={false}
            dot={<View style={styles.dot} />}
            activeDot={<View style={styles.activeDot} />}
          >
            {items.map((item, index) => renderCarouselItem(item, index))}
          </Swiper>
          <TouchableOpacity style={styles.button} onPress={onClose}>
            <Text style={styles.buttonText}>Commencer</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContent: {
    width: '80%',
    height: '70%',
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  wrapper: {},
  carouselItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselImage: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  dot: {
    backgroundColor: 'rgba(0,0,0,.2)',
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  activeDot: {
    backgroundColor: '#007aff',
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  button: {
    marginTop: 20,
    backgroundColor: 'tomato',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
});

export default ModalMobile;
