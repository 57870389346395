import React, { useState, useEffect } from 'react';
import { View, Text, Image, Modal, TouchableOpacity, StyleSheet, Dimensions, Button, Platform, Alert, Linking } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/FontAwesome';
import VisasScreen from '../screens/VisasScreen';
import DemandesScreen from '../screens/DemandesScreen';
import VoyageursScreen from '../screens/VoyageursScreen';
import MesVisasScreen from '../screens/MesVisasScreen';
import ModalMobile from '../components/ModalMobile';
import ModalWeb from '../components/ModalWeb';
import * as StoreReview from 'expo-store-review';

const { width: viewportWidth } = Dimensions.get('window');
const Tab = createBottomTabNavigator();

interface CarouselItem {
  url: string;
}

const MyTabs = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const carouselItems: CarouselItem[] = [
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/IgTMOiH8-t0yKYM/ad_identity_photo.jpg?v=1717454325' },
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/BVYsdfnsyqPtEw0/ad_identity_photo.jpeg?v=1717443950' },
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/RJOKSQI5eXguR8J/ad_identity_photo.jpeg?v=1685196534' },
  ];

  useEffect(() => {
    const checkIfModalShown = async () => {
      try {
        const hasShownModal = await AsyncStorage.getItem('hasShownModal');
        if (!hasShownModal) {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error('Failed to check modal shown status:', error);
      }
    };

    checkIfModalShown();
    incrementLoginCount();
  }, []);

  const handleModalClose = async () => {
    try {
      await AsyncStorage.setItem('hasShownModal', 'true');
      setIsModalVisible(false);
    } catch (error) {
      console.error('Failed to set modal shown status:', error);
    }
  };

  const resetModalShown = async () => {
    try {
      await AsyncStorage.removeItem('hasShownModal');
      setIsModalVisible(true);
    } catch (error) {
      console.error('Failed to reset modal shown status:', error);
    }
  };

  const incrementLoginCount = async () => {
    try {
      const loginCount = await AsyncStorage.getItem('loginCount');
      const newCount = loginCount ? parseInt(loginCount) + 1 : 1;
      await AsyncStorage.setItem('loginCount', newCount.toString());

      if (newCount >= 5) {
        await askForReview();
        await AsyncStorage.setItem('loginCount', '0'); // Reset the counter after asking for review
      }
    } catch (error) {
      console.error('Failed to increment login count:', error);
    }
  };

  const askForReview = async () => {
    try {
      await StoreReview.requestReview();
    } catch (error) {
      Alert.alert(
        "Notez notre application",
        "L'option de notation n'est pas disponible sur cet appareil. Vous pouvez nous laisser un avis sur le store.",
        [
          {
            text: "Annuler",
            style: "cancel"
          },
          {
            text: "OK",
            onPress: () => {
              // Rediriger vers l'URL de l'application sur le store
              if (Platform.OS === 'android') {
                Linking.openURL('market://details?id=com.yourapp.id');
              } else if (Platform.OS === 'ios') {
                Linking.openURL('itms-apps://itunes.apple.com/app/idYOUR_APP_ID');
              }
            }
          }
        ]
      );
    }
  };

  return (
    <>
      <Tab.Navigator
        screenOptions={({ route }) => ({
          gestureEnabled: false, // Désactiver le geste de glissement
          tabBarIcon: ({ color, size }) => {
            let iconName = 'circle'; // Valeur par défaut
            let customIcon: any = null;
            switch (route.name) {
              case 'Voyageurs':
                iconName = 'users';
                break;
              case 'Destinations':
                iconName = 'globe';
                break;
              case 'Demandes':
                iconName = 'file-text';
                break;
              case 'Mes visas':
                customIcon = require('../assets/passport.png');
                break;
              default:
                iconName = 'circle'; // Fallback icon
                break;
            }
            return customIcon ? (
              <Image source={customIcon} style={{ width: size, height: size }} />
            ) : (
              <Icon name={iconName} size={size} color={color} />
            );
          },
          tabBarActiveTintColor: 'green',
          tabBarInactiveTintColor: 'gray',
        })}
      >
        <Tab.Screen name="Destinations" component={VisasScreen} options={{ headerShown: false }} />
        <Tab.Screen name="Voyageurs" component={VoyageursScreen} options={{ headerShown: false }} />      
        <Tab.Screen name="Demandes" component={DemandesScreen} options={{ headerShown: false }} />
        <Tab.Screen name="Mes visas" component={MesVisasScreen} options={{ headerShown: false }} />
      </Tab.Navigator>

      {/* <Button title="Reset Tutorial" onPress={resetModalShown} /> */}


      {Platform.OS === 'web' ? (
        <ModalWeb isVisible={isModalVisible} onClose={handleModalClose} items={carouselItems} />
      ) : (
        <ModalMobile isVisible={isModalVisible} onClose={handleModalClose} items={carouselItems} />
      )}
    </>
  );
};

export default MyTabs;
