import axios from 'axios';

export const validerDemande = async (data) => {
    try {
      // Créer un objet FormData
      const formData = new FormData();
  
      // Ajouter les champs de données
      Object.keys(data).forEach(key => {
        // Pour les champs qui ne se terminent pas par 'Uri', les ajouter directement
        if (!key.endsWith('Uri')) {
          formData.append(key, data[key]);
        } else {
          // Pour les champs qui se terminent par 'Uri', traiter comme fichier
          const fileUri = data[key];
          if (fileUri) {
            console.log("here");
              // Vérifier si le fichier est au format base64 ou une URI de fichier
              if (fileUri.startsWith('data:')) {
                  // Si le fichier est au format base64, convertissez-le en Blob
                  const mimeType = fileUri.split(';')[0].split(':')[1];
    
                  // Décider de l'extension de fichier basée sur le type MIME
                  let fileExtension = '';
                  if (mimeType === 'application/pdf') {
                      fileExtension = 'pdf';
                  } else if (mimeType === 'image/jpeg') {
                      fileExtension = 'jpg';
                  } // Ajoutez d'autres types MIME selon vos besoins
                  else {
                    fileExtension = 'jpg';
                }
                const blob = dataURItoBlob(fileUri);
                const fieldName = key.replace(/Uri$/, ''); // Retire 'Uri' pour obtenir le nom du champ
                const fileName = `${fieldName}.${fileExtension}`; // Construit le nom du fichier avec l'extension
                formData.append(fieldName, blob, fileName);
              } else {
                  // Si le fichier est une URI de fichier, traitez-le comme un fichier
                  const fileExtension = fileUri.match(/\.(\w+)$/)?.[1]; // Extrait l'extension du fichier
                  const fieldName = key.replace(/Uri$/, ''); // Retire 'Uri' pour obtenir le nom du champ
                  const fileName = `${fieldName}.${fileExtension}`; // Construit le nom du fichier avec l'extension
                  formData.append(fieldName, {
                      uri: fileUri,
                      type: fileUri.endsWith('.pdf') ? 'application/pdf' : 'image/jpeg',
                      name: fileName, // Utilise le nom construit
                  });
              }
          }
      }
      });
  
      if (data.lettre) {
        // Vérifier si le fichier est au format base64 ou une URI de fichier
        if (data.lettre.startsWith('data:')) {
            // Si le fichier est au format base64, ajoutez-le directement
            formData.append('lettre', data.lettre);
        } else {
            // Si le fichier est une URI de fichier, traitez-le comme un fichier
            const fileExtension = data.lettre.match(/\.(\w+)$/)?.[1]; // Extrait l'extension du fichier
            const fileName = `lettre.${fileExtension}`; // Construit le nom du fichier avec l'extension
            formData.append('lettre', {
                uri: data.lettre,
                type: data.lettre.endsWith('.pdf') ? 'application/pdf' : 'image/jpeg',
                name: fileName, // Utilise le nom construit
            });
        }
    }
    
    
  
      // Log pour le débogage
      console.log("Données envoyées:", formData);
  
      // Appel API avec formData
      //const response = await axios.post('http://192.168.1.144:3001/validerDemande', formData, {
      //  const response = await axios.post('http://172.20.10.2:3001/validerDemande', formData, {
        const response = await axios.post('https://nodejs-appli.visamonde.com/validerDemande', formData, {  
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Demande validée avec succès:', response.data);
    } catch (error) {
      console.error('Erreur lors de la validation de la demande', error);
      throw error;
    }
  };
  
  function dataURItoBlob(dataURI) {
    // Convertit les données base64 en tableau de bytes
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extrait le type MIME
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // Crée un Blob avec les données et le type MIME
    return new Blob([ab], { type: mimeString });
}