import React, { useState, useEffect } from 'react';
import { View, Image, Text, StyleSheet, Alert } from 'react-native';
import * as ImagePicker from 'expo-image-picker';

const MyCameraComponentBis = ({ onPhotoTaken, onCancel }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [photoUri, setPhotoUri] = useState(null);
  const [isTakingPhoto, setIsTakingPhoto] = useState(true); // Nouvel état pour gérer le message

  useEffect(() => {
    (async () => {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  useEffect(() => {
    if (hasPermission) {
      takePicture();
    }
  }, [hasPermission]);

  const takePicture = async () => {
    if (hasPermission === null || !hasPermission) {
      Alert.alert('Permission', 'Vous devez autoriser l\'accès à la caméra pour utiliser cette fonctionnalité.');
      return;
    }
    
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.5,
    });

    if (result && !result.cancelled) {
      const photoUri = result.assets && result.assets.length > 0 ? result.assets[0].uri : null;
      if (photoUri) {
        setPhotoUri(photoUri);
        onPhotoTaken(photoUri);
      } else {
        setIsTakingPhoto(false); // Mettre à jour l'état pour masquer le message
        onCancel();
      }
    } else {
      setIsTakingPhoto(false); // Mettre à jour l'état pour masquer le message
      onCancel();
    }
  };

  if (hasPermission === null) {
    return <View><Text>Demande de permissions...</Text></View>;
  }
  if (hasPermission === false) {
    return <Text>Pas d'accès à la caméra</Text>;
  }

  return (
    <View style={styles.container}>
      {photoUri ? (
        <View style={styles.previewContainer}>
          <Image source={{ uri: photoUri }} style={styles.previewImage} />
        </View>
      ) : (
        isTakingPhoto && <View><Text>Prise de photo en cours...</Text></View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    width: '100%',
    height: '80%',
  },
});

export default MyCameraComponentBis;
