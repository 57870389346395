import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, Image, ScrollView, TouchableOpacity, ActivityIndicator } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native';
import { recupereDonneesDemande, recupererListeVoyageurs, updateDemande } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { validerDemande } from '../creationDemandeService';
import DocumentOrPhotoPicker from '../components/DocumentOrPhotoPicker';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFocusEffect } from '@react-navigation/native';
import { BackHandler, Alert, Platform } from 'react-native';

// Définition d'un type pour les paramètres de route de FormScreen
type FormScreenRouteParams = {
  title: string,
  prixconsulat: number,
  prixintervention: number,
  demandeId: number,
  url_flag: string,
};

// Supposons que vous avez une définition pour RootStackParamList qui inclut FormScreen
type RootStackParamList = {
  FormScreen: FormScreenRouteParams;
  Demandes: {};
};

type FormScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Demandes'>;

const FormThailande = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FormScreen'>>();
  const navigation = useNavigation<FormScreenNavigationProp>();
  const demandeId = route.params?.demandeId;
  const isEditMode = demandeId != null; // Mode édition si demandeId est présent
  const [isLoading, setIsLoading] = useState(false);
  
  const [title, setTitle] = useState(route.params?.title);
  const [prixconsulat, setPrixconsulat] = useState(route.params?.prixconsulat);
  const [prixintervention, setPrixintervention] = useState(route.params?.prixintervention);
  const [urlFlag, setUrlFlag] = useState(route.params?.url_flag);

  const [dateDepart, setdateDepart] = useState('');
  const [dateSortie, setdateSortie] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');

  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);

  const [assuranceVisa, setAssuranceVisa] = useState('');
  const [billetAvionUri, setBilletAvionUri] = useState('');
  const [preuveHebergementUri, setPreuveHebergementUri] = useState('');
  const [justificatifDomicileUri, setJustificatifDomicileUri] = useState('');
  const [releveBancaireUri, setReleveBancaireUri] = useState('');
  const [lettreInvitationUri, setLettreInvitationUri] = useState('');
  const [pieceIdentiteSignataireUri, setPieceIdentiteSignataireUri] = useState('');
  const [ordreMissionUri, setOrdreMissionUri] = useState('');
  const [registreCommerceUri, setRegistreCommerceUri] = useState('');

  const isBusiness = title.toLowerCase().includes('affaire');

  // Mettre à jour la liste des champs non remplis
  const updateChampsNonRemplis = () => {
    const champs = [];
    if (!dateDepart.trim()) champs.push('Date de départ');
    if (!dateSortie.trim()) champs.push('Date de sortie');
    if (!assuranceVisa.trim()) champs.push('Assurance Visa');
    if (!billetAvionUri) champs.push('Billet d\'avion');
    if (!justificatifDomicileUri) champs.push('Justificatif de domicile en France');

    if (isBusiness) {
      if (!releveBancaireUri) champs.push('Dernier relevé bancaire (>1000€)');
      if (!lettreInvitationUri) champs.push('Lettre d\'invitation entreprise Thaïlande');
      if (!pieceIdentiteSignataireUri) champs.push('Pièce d\'identité du signataire de la lettre d\'invitation');
      if (!ordreMissionUri) champs.push('Ordre de mission');
      if (!registreCommerceUri) champs.push('Registre du commerce de l’entreprise d\'emploi');
    } else {
      if (!preuveHebergementUri) champs.push('Preuve d\'hébergement');
    }
  
    setChampsNonRemplis(champs);
    setIsFormValid(champs.length === 0);
  };

  useEffect(() => {
    updateChampsNonRemplis();
  }, [dateDepart, dateSortie, assuranceVisa, billetAvionUri, justificatifDomicileUri, releveBancaireUri, lettreInvitationUri, pieceIdentiteSignataireUri, ordreMissionUri, registreCommerceUri, preuveHebergementUri]);

  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length > 0) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        }
      }
    };

    fetchVoyageurs().then(() => {});
  }, [demandeId]);

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.goBack();
          }
        } else {
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              {
                text: 'Annuler',
                onPress: () => null,
                style: 'cancel',
              },
              {
                text: 'OK',
                onPress: () => navigation.goBack(),
              },
            ],
            { cancelable: false }
          );
        }
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      const unsubscribe = navigation.addListener('beforeRemove', (e) => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.dispatch(e.data.action);
          }
        } else {
          e.preventDefault();
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              { text: "Annuler", style: 'cancel', onPress: () => {} },
              { text: "OK", style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
            ]
          );
        }
      });

      return () => {
        backHandler.remove();
        unsubscribe();
      };
    }, [navigation])
  );

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = await AsyncStorage.getItem('@user');
     
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        assuranceVisa: assuranceVisa,
        billetAvionUri, justificatifDomicileUri, releveBancaireUri, lettreInvitationUri,
        pieceIdentiteSignataireUri, ordreMissionUri, registreCommerceUri, preuveHebergementUri,
        
      };

      await validerDemande(data);
      console.log('Demande validée avec succès');
      setIsLoading(false);
      navigation.navigate('Demandes', '');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    const chars = numbers.split('');
    let formattedInput = '';

    chars.forEach((char, index) => {
      if (index === 2 || index === 4) {
        formattedInput += '/';
      }
      formattedInput += char;
    });

    return formattedInput.slice(0, 10);
  };

  const disabledPressHandler = () => {};

  const backgroundColor = champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc';

  return (
    <KeyboardAwareScrollView style={styles.scrollView} extraHeight={250}>
      <View style={styles.container}>
        <Image source={{ uri: urlFlag }} style={styles.flag} />
        <Text style={styles.title}>Formulaire pour {title}</Text>
        <Text style={styles.libelle}>Prix consulat {prixconsulat}€ Prix intervention {prixintervention}€</Text>
        
        {!isEditMode && (
          <View>
            <Text style={styles.libelle}>Sélectionner un voyageur</Text>
            <View style={styles.pickerContainer}>
              <RNPickerSelect
                onValueChange={(value) => setSelectedVoyageur(value)}
                items={voyageurs.map((voyageur) => ({
                  label: `${voyageur.nom} ${voyageur.prenom}`,
                  value: voyageur.id.toString(),
                }))}
                placeholder={{
                  label: 'Sélectionner un voyageur',
                  value: null,
                }}
                style={pickerSelectStyles}
                value={selectedVoyageur}
                useNativeAndroidPickerStyle={false}
              />
            </View>
          </View>
        )}
        {selectedVoyageurObj && (
          <View style={styles.avatarContainer}>
            <Image
              source={{ uri: selectedVoyageurObj.url || 'https://example.com/default-avatar.png' }}
              style={styles.avatar}
            />
          </View>
        )}

        <View style={styles.inputContainer}>
          <Text>Date de départ de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de départ de votre voyage jj/mm/aaaa"
            value={dateDepart}
            onChangeText={(text) => setdateDepart(formatDate(text))}
          />
        </View>

        <View style={styles.inputContainer}>
          <Text>Date de retour de votre voyage :</Text>
          <TextInput
            style={[styles.textInput]}
            placeholder="Date de retour de votre voyage jj/mm/aaaa"
            value={dateSortie}
            onChangeText={(text) => setdateSortie(formatDate(text))}
          />
        </View>

        <DocumentOrPhotoPicker
          documentType="Billet d'avion"
          onDocumentSelected={setBilletAvionUri}
          modeOptions={['photo', 'selectPhoto', 'selectPdf']}
        />

        <DocumentOrPhotoPicker
          documentType="Justificatif de domicile en France"
          onDocumentSelected={setJustificatifDomicileUri}
          modeOptions={['photo', 'selectPhoto', 'selectPdf']}
        />

        {!isBusiness && (
          <DocumentOrPhotoPicker
            documentType="Preuve d'hébergement (nom/prénom)"
            onDocumentSelected={setPreuveHebergementUri}
            modeOptions={['photo', 'selectPhoto', 'selectPdf']}
          />
        )}

        {isBusiness && (
          <View>
            <DocumentOrPhotoPicker
              documentType="Dernier relevé bancaire (>1000€)"
              onDocumentSelected={setReleveBancaireUri}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
            <DocumentOrPhotoPicker
              documentType="Lettre d'invitation entreprise Thaïlande"
              onDocumentSelected={setLettreInvitationUri}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
            <DocumentOrPhotoPicker
              documentType="Pièce d'identité du signataire de la lettre d'invitation"
              onDocumentSelected={setPieceIdentiteSignataireUri}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
            <DocumentOrPhotoPicker
              documentType="Ordre de mission"
              onDocumentSelected={setOrdreMissionUri}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
            <DocumentOrPhotoPicker
              documentType="Registre du commerce de l’entreprise d'emploi"
              onDocumentSelected={setRegistreCommerceUri}
              modeOptions={['photo', 'selectPhoto', 'selectPdf']}
            />
          </View>
        )}

        <AssuranceVisaComponent
          assuranceVisa={assuranceVisa}
          setAssuranceVisa={setAssuranceVisa}
        />

        <View style={[styles.champsNonRemplisContainer, { backgroundColor }]}>
          {champsNonRemplis.length > 0 ? (
            <>
              <Text>Formulaire incomplet. Champs manquants :</Text>
              {champsNonRemplis.map((champ, index) => (
                <Text key={index}>{champ}</Text>
              ))}
            </>
          ) : (
            <Text>Formulaire complet</Text>
          )}
        </View>

        {isLoading ? (
          <ActivityIndicator size="large" color="#0000ff" />
        ) : (
          <View style={styles.buttonContainer}>
            <TouchableOpacity
              onPress={isFormValid ? handleSubmit : disabledPressHandler}
              style={[
                styles.button,
                { backgroundColor: isFormValid ? '#007AFF' : '#A9A9A9' },
              ]}
              disabled={!isFormValid}
            >
              <Text style={styles.buttonText}>Valider ma demande</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  radioButtonOption: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: 'grey',
    padding: 10,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007AFF',
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
  },
  imagePickerButton: {
    backgroundColor: '#4e9af1',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: '80%',
    alignSelf: 'center',
  },
  imagePreview: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
  },
  champsNonRemplisContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInput: {
    marginBottom: 20,
    width: '100%',
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#d3d3d3', // Fond gris clair
  },
  inputContainer: {
    marginBottom: 15,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginTop: 5,
    backgroundColor: 'white', // Fond blanc
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#d3d3d3', // Fond gris clair
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  libelle: {
    fontSize: 15,
    marginBottom: 20,
  },
  pickerContainer: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 50,
  },
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
  flag: {
    width: 50,
    height: 30,
    alignSelf: 'center',
    marginBottom: 20,
  }
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30,
    backgroundColor:'white'
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30,
    backgroundColor:'white'
  },
});

export default FormThailande;
