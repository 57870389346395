import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Alert, Platform, Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { deleteAccount, recupereListeFactures } from '../Services';
import RenderHTML from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import * as FileSystem from 'expo-file-system';
import PDFReader from 'rn-pdf-reader-js';
import ModalMobile from '../components/ModalMobile';
import ModalWeb from '../components/ModalWeb';
import { Icon } from 'react-native-elements';

type RootStackParamList = {
  Login: undefined;
};
interface CarouselItem {
  url: string;
}

type ParameterScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Login'>;

const ParameterScreen = () => {
  const [userName, setUserName] = useState('');
  const [userMail, setUserMail] = useState('');
  const [factures, setFactures] = useState<string>('');
  const [visas, setVisas] = useState<string[]>([]);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const carouselItems: CarouselItem[] = [
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/IgTMOiH8-t0yKYM/ad_identity_photo.jpg?v=1717454325' },
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/BVYsdfnsyqPtEw0/ad_identity_photo.jpeg?v=1717443950' },
    { url: 'https://common.clicktoclub.com/files/0/74/pdf/RJOKSQI5eXguR8J/ad_identity_photo.jpeg?v=1685196534' },
  ];

  const navigation = useNavigation<ParameterScreenNavigationProp>();
  const { width } = useWindowDimensions();

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userJson = await AsyncStorage.getItem('@user');
        if (userJson !== null) {
          const user = JSON.parse(userJson);
          setUserName(user.nom);
          setUserMail(user.email);
          const facturesHtml = await recupereListeFactures(user.email);
          setFactures(facturesHtml);
        }
      } catch (error) {
        console.error('Failed to load user.', error);
      }
    };

    const loadVisas = async () => {
      try {
        const documentDirectory = FileSystem.documentDirectory;
        if (documentDirectory) {
          const directory = await FileSystem.readDirectoryAsync(documentDirectory);
          const pdfFiles = directory.filter((file: string) => file.endsWith('.pdf'));
          setVisas(pdfFiles);
        } else {
          console.error('documentDirectory is null');
        }
      } catch (error) {
        console.error('Failed to load visas.', error);
      }
    };

    loadUser();
    loadVisas();
  }, []);

  const handleLogout = async () => {
    try {
      await AsyncStorage.removeItem('@user');
      navigation.navigate('Login');
    } catch (error) {
      console.error('Failed to logout.', error);
    }
  };

  const handleDeleteAccount = async () => {
    Alert.alert(
      "Supprimer le compte",
      "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.",
      [
        {
          text: "Annuler",
          style: "cancel"
        },
        { 
          text: "Supprimer", onPress: async () => {
            try {
              const userMail = await AsyncStorage.getItem('@mail');
              if (userMail !== null) {
                await deleteAccount(userMail);
                await AsyncStorage.removeItem('@user');
                await AsyncStorage.removeItem('@mail');
                Alert.alert("", "Votre compte utilisateur a bien été supprimé.");
                navigation.navigate('Login');
              } else {
                Alert.alert("Erreur", "Impossible de récupérer les informations de l'utilisateur.");
              }
            } catch (error) {
              console.error('Failed to delete account.', error);
              Alert.alert("Erreur", "La suppression du compte a échoué.");
            }
          }
        }
      ]
    );
  };

  const openPdf = (fileName: string) => {
    const fileUri = `${FileSystem.documentDirectory}${fileName}`;
    setSelectedPdf(fileUri);
  };

  const closePdf = () => {
    setSelectedPdf(null);
  };

  const handleTutorialPress = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleContactPress = () => {
    Linking.openURL("https://beta.visamonde.com/#contact");
  };

  const handleFaqPress = () => {
    Linking.openURL("https://beta.visamonde.com/FAQ.html");
  };

  return (
    <View style={styles.container}>
      {selectedPdf ? (
        <>
          <PDFReader
            source={{ uri: selectedPdf }}
            style={{ flex: 1 }}
          />
          <TouchableOpacity style={styles.closeButton} onPress={closePdf}>
            <Text style={styles.closeButtonText}>Fermer</Text>
          </TouchableOpacity>
        </>
      ) : (
        <>
          <View style={styles.header}>
            <Text style={styles.userName}>Bonjour, {userName}</Text>
          </View>
          <ScrollView style={styles.content}>
            <View style={styles.card}>
              <Text style={styles.textContent}>
                Liste de vos factures:
              </Text>
              <RenderHTML contentWidth={width} source={{ html: factures }} />
            </View>
           
          </ScrollView>
          <View style={styles.footer}>
          <TouchableOpacity style={styles.optionItem} onPress={handleTutorialPress}>
          <View style={styles.optionContent}>
            <Icon name="help-outline" size={24} color="#007AFF" />
            <Text style={styles.optionText}>Voir le tutoriel</Text>
          </View>
          <Icon name="chevron-right" size={24} color="#007AFF" />
        </TouchableOpacity>

        {/* Bouton Contact */}
        <TouchableOpacity style={styles.optionItem} onPress={handleContactPress}>
          <View style={styles.optionContent}>
            <Icon name="info-outline" size={24} color="#007AFF" />
            <Text style={styles.optionText}>Contactez-nous</Text>
          </View>
          <Icon name="chevron-right" size={24} color="#007AFF" />
        </TouchableOpacity>

         {/* Bouton FAQ */}
         <TouchableOpacity style={styles.optionItem} onPress={handleFaqPress}>
          <View style={styles.optionContent}>
            <Icon name="question-answer" size={24} color="#007AFF" />
            <Text style={styles.optionText}>FAQ</Text>
          </View>
          <Icon name="chevron-right" size={24} color="#007AFF" />
        </TouchableOpacity>


            <TouchableOpacity style={styles.iosButton} onPress={handleLogout}>
              <Text style={styles.iosButtonText}>Déconnexion</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.iosButton, { backgroundColor: 'black' }]} onPress={handleDeleteAccount}>
              <Text style={styles.iosButtonText}>Supprimer mon compte</Text>
            </TouchableOpacity>
          </View>
        </>
      )}
      {Platform.OS === 'web' ? (
        <ModalWeb isVisible={isModalVisible} onClose={handleModalClose} items={carouselItems} />
      ) : (
        <ModalMobile isVisible={isModalVisible} onClose={handleModalClose} items={carouselItems} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
    marginRight: 10, // Adjust as needed for spacing between items
  },
  visaContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginVertical: 10,
  },
  textContent: {
    fontSize: 16,
    color: 'black',
    marginBottom: 10,
  },
  footer: {
    width: '100%',
    padding: 10,
  },
  tutorialButton: {
    backgroundColor: 'blue',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  iosButton: {
    backgroundColor: 'black',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 10,
    alignItems: 'center',
  },
  iosButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  container: {
    flex: 1,
    backgroundColor: '#f8f8f8',
  },
  header: {
    marginTop: 50,
    marginBottom: 20,
    alignItems: 'center',
  },
  userName: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  content: {
    flex: 1,
    paddingHorizontal: 20,
  },
  fileItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  closeButton: {
    backgroundColor: 'black',
    padding: 10,
    paddingBottom:30,
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  optionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 8,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 3,
  },
  optionContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionText: {
    fontSize: 16,
    marginLeft: 10,
    color: 'black',
  },
});

export default ParameterScreen;
