// services.ts
import axios from 'axios';
import { Visas } from './models/Visas';
import { Voyageurs } from './models/Voyageurs';
import { Demandes } from './models/Demandes';
import { Pays } from './models/Pays';
import { AdresseFacture } from './models/AdresseFacture';

//const BASE_URL = 'http://192.168.1.144:3001'; // Ajustez cela en fonction de l'URL de votre serveur backend
//const BASE_URL = 'http://172.20.10.2:3001';
const BASE_URL = 'https://nodejs-appli.visamonde.com';

// Définition des types pour les paramètres attendus par votre API pourrait être utile
interface ValiderDemandeParams {
  idUtilisateur: number;
  idVoyageur: string;
  titreVisa: string;
  dateDepart: string;
  nomContact: string;
  prixconsulat : number;
  prixintervention : number;
}

interface SignUpParams {
  nom: string;
  email: string;
  motDePasse: string;
  telephone: string;
}

// Services.ts
// Fonction pour envoyer un email de réinitialisation du mot de passe
export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    const response = await axios.post(`${BASE_URL}/reset-password`, { email });
    console.log('Email de réinitialisation envoyé avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de l\'envoi de l\'email de réinitialisation', error);
    throw new Error('Échec de l\'envoi de l\'email de réinitialisation');
  }
};


export const signUp = async ({ nom, email, motDePasse, telephone }: SignUpParams) => {
  try {
    const response = await axios.post(`${BASE_URL}/utilisateurs/signUp`, {
      nom,
      email,
      motDePasse,
      telephone
    });

    // Traiter la réponse ici, par exemple en retournant les données ou en gérant les erreurs
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'inscription:', error);
    // Ici, vous pourriez vouloir gérer l'erreur ou la renvoyer pour la traiter plus haut dans la chaîne
    throw error;
  }
};

export const deleteAccount = async (userMail: string): Promise<void> => {
  try {
    // Ici, nous supposons que votre API requiert l'ID de l'utilisateur pour supprimer le compte.
    // La méthode HTTP et les détails de l'endpoint peuvent varier selon votre implémentation d'API.
    await axios.delete(`${BASE_URL}/utilisateurs/${userMail}`);

    console.log('Compte supprimé avec succès');
  } catch (error) {
    console.error('Erreur lors de la suppression du compte', error);
    throw new Error('Erreur lors de la suppression du compte');
  }
};

export const recupereListeFactures = async (userMail: string): Promise<string> => {
  try {
    console.log(userMail);
    const response = await axios.get(`${BASE_URL}/factures`, {
      params: { email: userMail }
    });
    return response.data; // Supposons que le backend renvoie du HTML
  } catch (error) {
    console.error('Erreur lors de la récupération des factures', error);
    throw error;
  }
};


// Fonction pour récupérer les données d'une demande spécifique
export const recupereDonneesDemande = async (demandeId: number) => {
  try {
    const response = await axios.get(`${BASE_URL}/recupereDonneesDemande/${demandeId}`);
    return response.data; // retourne les données de la demande
  } catch (error) {
    console.error('Erreur lors de la récupération des données de la demande', error);
    throw error; // Lève une exception pour gérer l'erreur en amont
  }
};

export const updateDemande = async (data : any) => {
  try {
    // Assurez-vous que l'URL et le corps de la requête correspondent à ce que votre API attend
    const response = await axios.put(`${BASE_URL}/updateDemande`, data);
    console.log('Demande mise à jour avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la demande', error);
    throw error;
  }
};



export const insertAdresseFacturation = async (idUtilisateur : number, bonId : number, adresse : AdresseFacture) => {
  try {
    const response = await axios.post(`${BASE_URL}/adresseFacturation/insert`, {
      id_utilisateur: idUtilisateur,
      bon_id: bonId,
      nom: adresse.nom,
      adresse_ligne1: adresse.adresse_ligne1,
      adresse_ligne2: adresse.adresse_ligne2,
      ville: adresse.ville,
      code_postal: adresse.code_postal,
      pays: adresse.pays,
      telephone: adresse.telephone
    });

    return response.data; // Renvoie la réponse du serveur
  } catch (error) {
    console.error('Erreur lors de l\'insertion de l\'adresse de facturation:', error);
    throw error;
  }
};

export const updateAdresseFacturation = async (idUtilisateur : number, bonId : number, adresse : AdresseFacture) => {
  try {
    const response = await axios.put(`${BASE_URL}/adresseFacturation/update`, {
      id: adresse.id, // Assurez-vous que l'adresse a un id
      id_utilisateur: idUtilisateur,
      bon_id: bonId,
      nom: adresse.nom,
      adresse_ligne1: adresse.adresse_ligne1,
      adresse_ligne2: adresse.adresse_ligne2,
      ville: adresse.ville,
      code_postal: adresse.code_postal,
      pays: adresse.pays,
      telephone: adresse.telephone
    });

    return response.data; // Renvoie la réponse du serveur
  } catch (error) {
    console.error('Erreur lors de la mise à jour de l\'adresse de facturation:', error);
    throw error;
  }
};

// La fonction pour récupérer les adresses de facturation d'un utilisateur
export const recupereAdressesFacturation = async (idUtilisateur : number) => {
  try {
      const response = await axios.get(`${BASE_URL}/adressesfacturation/${idUtilisateur}`);
      return response.data;
  } catch (error) {
      console.error('Erreur lors de la récupération des adresses de facturation:', error);
      throw error;
  }
};

export const payer = async (demandeIds: number[]): Promise<{urlPaiement: string, bonId: number}> => {
  try {
    const response = await axios.post(`${BASE_URL}/payer`, { demandeIds });
    return {
      urlPaiement: response.data.urlPaiement, // URL de paiement
      bonId: response.data.bonId // Bon ID
    };
  } catch (error) {
    console.error('Erreur lors du paiement:', error);
    throw error;
  }
};


export const recupererListePays = async (): Promise<Pays[]> => {
  try {
    // Envoyez une requête GET à votre serveur pour récupérer la liste des pays
    const response = await axios.get<Pays[]>(`${BASE_URL}/pays`);
    return response.data; // Supposant que le serveur renvoie directement un tableau de pays
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des pays", error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

export const recupererListeDemandes = async (userId: number): Promise<Demandes[]> => {
  try {
    const response = await axios.get<Demandes[]>(`${BASE_URL}/demandes?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des demandes", error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

export const recupererListeVisasUtilisateur = async (userId: number): Promise<Demandes[]> => {
  try {
    const response = await axios.get<Demandes[]>(`${BASE_URL}/visasutilisateur?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des demandes", error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

export const supprimerDemandeService = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/demandes/${id}`);
    console.log(`Demande avec l'ID ${id} supprimée avec succès.`);
  } catch (error) {
    console.error(`Erreur lors de la suppression de la demande avec l'ID ${id}`, error);
    throw error;
  }
};

// Fonction pour déterminer le type MIME en fonction de l'extension du fichier
const getFileType = (uri: string): string => {
  if (uri.startsWith('data:image/jpeg')) return 'image/jpeg';
  if (uri.startsWith('data:image/png')) return 'image/png';
  if (uri.startsWith('data:application/pdf')) return 'application/pdf';

  const extension = uri.split('.').pop()?.toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
};



// Fonction validerDemande qui gère les fichiers et les champs texte
export const validerDemande = async (data: any): Promise<void> => {
  try {
    const formData = new FormData();

    // Parcours de chaque champ de `data`
    for (const key in data) {
      const value = data[key];

      if (key.toLowerCase().includes('uri') && typeof value === 'object' && value.uri) {
        // Ajoutez le fichier directement à FormData
        formData.append(
          key,
          {
            uri: value,
            type: getFileType(value),
            name: `${key}.${value.split('.').pop()}`,
          } as any // Ici, nous indiquons à TypeScript de traiter cet objet comme `any`
        );
      } else {
        formData.append(key, value);
      }
    }

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // Envoi de la demande avec axios
    const response = await axios.post(`${BASE_URL}/validerDemande`, formData, config);
    console.log('Demande validée avec succès:', response.data);
  } catch (error) {
    console.error('Erreur lors de la validation de la demande:', error);
    throw error;
  }
};



export const login = async (email: string, password: string): Promise<any> => {
  try {
    // Préparez le corps de la requête avec l'email et le mot de passe
    const body = { email, password };

    // Envoyez une requête POST à votre serveur pour tenter de connecter l'utilisateur
    // Assurez-vous que l'URL est correcte en fonction de votre API
    const response = await axios.post(`${BASE_URL}/login`, body);

    console.log('Connexion réussie:', response.data);
    // Retourner la réponse du serveur, souvent des informations utilisateur ou un token d'authentification
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la tentative de connexion', error);
    // Vous pouvez choisir de re-throw l'erreur pour la gérer à un niveau supérieur
    throw error;
  }
};


export async function recupererListeVisas(): Promise<Visas[]> {
  try {
    const response = await axios.get<Visas[]>(`${BASE_URL}/visas`);
    return response.data; // Supposant que le serveur renvoie directement un tableau de visas
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des visas", error);
    return []; // Retourne un tableau vide ou gère l'erreur comme tu préfères
  }
}

export const miseAJourVoyageur = async (id: number, nom: string, prenom: string): Promise<void> => {
  try {
    // Préparez le corps de la requête avec les données mises à jour du voyageur
    const body = { nom, prenom };

    // Envoyez une requête PUT à votre serveur pour mettre à jour le voyageur
    // Assurez-vous que l'URL et le corps de la requête sont corrects en fonction de votre API
    const response = await axios.put(`${BASE_URL}/voyageurs/${id}`, body);

    console.log('Voyageur mis à jour avec succès:', response.data);
    // Gérer la réponse du serveur comme nécessaire
  } catch (error) {
    console.error('Erreur lors de la mise à jour du voyageur', error);
    // Vous pouvez choisir de re-throw l'erreur ou de la gérer différemment
    throw error;
  }
};


export const recupererListeVoyageurs = async (userId: number): Promise<Voyageurs[]> => {
  try {
    // Ajouter l'ID de l'utilisateur comme paramètre de requête
    const response = await axios.get<Voyageurs[]>(`${BASE_URL}/voyageurs?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la liste des voyageurs", error);
    return []; // Retourne un tableau vide en cas d'erreur
  }
};

  export const supprimerVoyageurService = async (id: number): Promise<void> => {
    try {
      // Remplacez '/voyageurs/' par l'endpoint correct pour supprimer un voyageur dans votre API
      await axios.delete(`${BASE_URL}/voyageurs/${id}`);
      console.log(`Voyageur avec l'ID ${id} supprimé avec succès.`);
    } catch (error) {
      console.error(`Erreur lors de la suppression du voyageur avec l'ID ${id}`, error);
      // Vous pouvez choisir de re-throw l'erreur ou de la gérer différemment
      throw error;
    }
  };

  export const creerVoyageur = async (formData: FormData): Promise<void> => {
    try {
        // Config pour la requête
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const response = await axios.post(`${BASE_URL}/voyageurs`, formData, config);
        console.log('Voyageur créé avec succès:', response.data);
    } catch (error) {
        console.error('Erreur lors de la création du voyageur', error);
        throw error;
    }
};




  