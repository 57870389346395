import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground, ScrollView, Alert, Platform, Modal, Linking } from 'react-native';
import { Demandes } from '../models/Demandes';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { recupererListeVisasUtilisateur } from '../Services';
import Header from '../components/Header';
import { useFocusEffect } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialIcons'; // Pour l'icône de téléchargement
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import NetInfo from '@react-native-community/netinfo';
import PDFReader from 'rn-pdf-reader-js';

const MesVisasScreen: React.FC = () => {
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [downloadedVisas, setDownloadedVisas] = useState<string[]>([]);
  const [offlineVisas, setOfflineVisas] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const [selectedPdf, setSelectedPdf] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const fetchDemandes = async () => {
    try {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const userId = user.id_utilisateur; // Assurez-vous que c'est la bonne clé
        const demandesData = await recupererListeVisasUtilisateur(userId);
        setDemandes(demandesData);
        const downloadedVisasData = await AsyncStorage.getItem('@downloadedVisas');
        if (downloadedVisasData) {
          setDownloadedVisas(JSON.parse(downloadedVisasData));
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  const fetchOfflineVisas = async () => {
    try {
      const documentDirectory = FileSystem.documentDirectory;
      if (documentDirectory) {
        const directory = await FileSystem.readDirectoryAsync(documentDirectory);
        const pdfFiles = directory.filter((file: string) => file.endsWith('.pdf'));
        setOfflineVisas(pdfFiles);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des visas hors ligne:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setIsConnected(state.isConnected ?? false); // Définit la valeur par défaut à `false` si `state.isConnected` est `null`
    });

    fetchDemandes();
    fetchOfflineVisas();

    return () => {
      unsubscribe();
    };
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetchDemandes();
      fetchOfflineVisas();
    }, [])
  );

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return ''; // Si la date est nulle ou vide, retourner une chaîne vide

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont de 0 à 11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const downloadFile = async (url: string, fileName: string) => {
    try {
      const fileUri = `${FileSystem.documentDirectory}${fileName}`;

      // Télécharger le fichier et l'enregistrer à l'emplacement temporaire de l'application
      const { uri } = await FileSystem.downloadAsync(url, fileUri);

      Alert.alert(
        'Téléchargement réussi',
        'Votre visa est bien enregistré sur votre mobile et sera accessible même hors connexion.'
      );      //console.log('Fichier téléchargé à:', uri);

      // Enregistrer l'état du téléchargement
      const updatedDownloadedVisas = [...downloadedVisas, fileName];
      setDownloadedVisas(updatedDownloadedVisas);
      await AsyncStorage.setItem('@downloadedVisas', JSON.stringify(updatedDownloadedVisas));
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier:', error);
      Alert.alert('Erreur de téléchargement', 'Une erreur est survenue lors du téléchargement du fichier.');
    }
  };

  const shareFile = async (fileName: string) => {
    const fileUri = `${FileSystem.documentDirectory}${fileName}`;
    try {
      await Sharing.shareAsync(fileUri);
    } catch (error) {
      console.error('Erreur lors du partage du fichier:', error);
    }
  };

  const gridItemWidth = demandes.length === 1 ? '90%' : '45%';

  const handlePressCard = (chemin: string) => {
    const url = `https://common.visamonde.com/demandes/${chemin}/visa.pdf`;
    Linking.openURL(url);
  };

  const renderVisaCard = (visa: string, index: number) => (
    <TouchableOpacity
      key={index}
      style={[styles.card, { width: gridItemWidth }]}
      onPress={() => {
        const fileUri = `${FileSystem.documentDirectory}${visa}`;
        setSelectedPdf(fileUri);
        setIsModalVisible(true);
      }}
    >
      <Text style={styles.text}>{visa}</Text>
    </TouchableOpacity>
  );

  const closePdf = () => {
    setSelectedPdf(null);
    setIsModalVisible(false);
  };

  return (
    <ImageBackground source={{ uri: 'https://beta.visamonde.com/assets/mobile/fond.jpg' }} style={styles.background}>
      <Header />
      <Modal
        visible={isModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={closePdf}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {selectedPdf && (
              <PDFReader
                source={{ uri: selectedPdf }}
                style={{ flex: 1 }}
              />
            )}
            <TouchableOpacity style={styles.closeButton} onPress={closePdf}>
              <Text style={styles.closeButtonText}>Fermer</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      {isConnected ? (
        demandes.length === 0 ? (
          <View style={styles.centeredMessage}>
            <Text style={styles.messageText}>Vous n'avez aucun visa traité actuellement.</Text>
          </View>
        ) : (
          <ScrollView contentContainerStyle={styles.grid}>
            {demandes.map((item, index) => {
              const fileName = `${item.titre}_${item.prenom}_${item.nom}.pdf`;
              const isDownloaded = downloadedVisas.includes(fileName);
              return (
                <TouchableOpacity
                  key={index}
                  style={[styles.card, { width: gridItemWidth }]}
                  onPress={() => handlePressCard(item.chemin)}
                >
                  {Platform.OS !== 'web' && (
                    !isDownloaded ? (
                      <TouchableOpacity style={styles.downloadButton} onPress={() => downloadFile(`https://common.visamonde.com/demandes/${item.chemin}/visa.pdf`, fileName)}>
                        <Icon name="file-download" size={24} color="#FFFFFF" />
                        <Text style={styles.downloadButtonText}>Télécharger</Text>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity style={styles.downloadButton} onPress={() => shareFile(fileName)}>
                        <Icon name="share" size={24} color="#FFFFFF" />
                        <Text style={styles.downloadButtonText}>Partager</Text>
                      </TouchableOpacity>
                    )
                  )}
                  <Image source={{ uri: item.urlflag }} style={styles.flagImage} />
                  <View style={styles.details}>
                    <Text style={styles.title}>{item.titre}</Text>
                    <Text style={styles.text}>{`${item.nom} ${item.prenom}`}</Text>
                    <Text style={styles.text}>{`Départ: ${item.datedepart}`}</Text>
                  </View>
                  <View style={styles.avatarWrapper}>
                    <Image source={{ uri: item.urlvoyageur }} style={styles.avatar} />
                  </View>
                  {item.datevalidite && <Text style={styles.text}>{`Valide du: ${formatDate(item.datevalidite)}`}</Text>}
                  {item.dateexpiration && <Text style={styles.text}>{`Au: ${formatDate(item.dateexpiration)}`}</Text>}
                  <Text style={styles.text}>{` ${item.infosvisa}`}</Text>
                  <View style={styles.buttonWrapper}>
                    <Text style={styles.iosButtonText}>Accéder à mon visa</Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        )
      ) : (
        <ScrollView contentContainerStyle={styles.grid2}>
          {offlineVisas.map((visa, index) => renderVisaCard(visa, index))}
        </ScrollView>
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  flagImage: {
    width: 70, // Ajustez la largeur selon vos besoins
    height: 40, // Ajustez la hauteur selon vos besoins
    resizeMode: 'contain', // Conserve les proportions de l'image
    alignSelf: 'center', // Centre l'image horizontalement
    marginTop: 10, // Ajoute une marge en haut
  },
  downloadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'green', // Couleur bleue moins forte
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  downloadButtonText: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 10,
    textAlign: 'center',
  },
  centeredMessage: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20, // Ajustez selon l'espace nécessaire autour du message
  },
  messageText: {
    textAlign: 'center',
    fontSize: 18, // Ajustez la taille du texte selon vos besoins
    color: '#ffffff', // Couleur du texte
  },
  buttonWrapper: {
    backgroundColor: 'black', // Couleur bleue moins forte
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarWrapper: {
    alignItems: 'center', // Centre l'avatar horizontalement
    marginBottom: 10, // Ajoutez de l'espace avant le bouton
  },
  iosButton: {
    backgroundColor: '#3399FF', // Couleur bleue moins forte
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iosButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    
  },
  grid2: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: 10,
    backgroundColor: '#f0f0f0', // Light background color
    borderRadius: 10, // Rounded corners for the grid
    margin: 10, // Margin around the grid
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    overflow: 'hidden',
    marginBottom: 10,
    margin: 5,
  },
  avatarContainer: {
    marginTop: 10, // Ajouter une marge pour séparer de la section des détails
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    padding: 10,
  },
  image: {
    width: '100%',
    height: 100,
    resizeMode: 'cover',
  },
  details: {
    padding: 10,
    flex: 1, // Assurez-vous que les détails prennent l'espace restant
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center', 
  },
  text: {
    textAlign: 'center', // Centrer le texte horizontalement
  },
  closeButton: {
    backgroundColor: 'red',
    padding: 10,
    paddingBottom:30,
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
    width: '90%',
    height: '90%',
  },
});

export default MesVisasScreen;
