import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, StyleSheet, Image, ScrollView, TouchableOpacity, ActivityIndicator, Alert, BackHandler, Platform } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useRoute, RouteProp, useNavigation, useFocusEffect } from '@react-navigation/native';
import { recupereDonneesDemande, recupererListeVoyageurs, updateDemande } from '../Services';
import { Voyageurs } from '../models/Voyageurs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { validerDemande } from '../creationDemandeService';
import DocumentOrPhotoPicker from '../components/DocumentOrPhotoPicker';
import AssuranceVisaComponent from '../components/AssuranceVisaComponent';
import { KeyboardAvoidingView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

// Définition d'un type pour les paramètres de route de FormScreen
type FormScreenRouteParams = {
  title: string,
  prixconsulat: number, 
  prixintervention :number,
  demandeId : number,
  url_flag: string
};

// Supposons que vous avez une définition pour RootStackParamList qui inclut FormScreen
type RootStackParamList = {
  FormScreen: FormScreenRouteParams;
  Demandes: {  };
};

type FormScreenNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Demandes'>;

const FormArabieSaoudite = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FormScreen'>>();
  const navigation = useNavigation<FormScreenNavigationProp>();
  const demandeId = route.params?.demandeId;
  const isEditMode = demandeId != null; // Mode édition si demandeId est présent
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState(route.params?.title);
  const [prixconsulat, setPrixconsulat] = useState(route.params?.prixconsulat);
  const [prixintervention, setPrixintervention] = useState(route.params?.prixintervention);

  const [dateDepart, setdateDepart] = useState('');
  const [dateSortie, setdateSortie] = useState('');
  const [voyageurs, setVoyageurs] = useState<Voyageurs[]>([]);
  const [selectedVoyageur, setSelectedVoyageur] = useState('');

  const [champsNonRemplis, setChampsNonRemplis] = useState<string[]>([]);

  const [assuranceVisa, setAssuranceVisa] = useState('');

  const [statutMarital, setStatutMarital] = useState('');
  const [statutProfessionnel, setStatutProfessionnel] = useState('');
  const [nomEtablissement, setNomEtablissement] = useState('');
  const [adresseEtablissement, setAdresseEtablissement] = useState('');
  const [telephoneEtablissement, setTelephoneEtablissement] = useState('');
  const [emailEtablissement, setEmailEtablissement] = useState('');
  const urlFlag = route.params?.url_flag;
  const prixTotal = prixconsulat + prixintervention;
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const disabledPressHandler = () => {};

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.goBack();
          }
        } else {
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              {
                text: 'Annuler',
                onPress: () => null,
                style: 'cancel',
              },
              {
                text: 'OK',
                onPress: () => navigation.goBack(),
              },
            ],
            { cancelable: false }
          );
        }
        return true;
      };
  
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress
      );
  
      const unsubscribe = navigation.addListener('beforeRemove', (e) => {
        if (Platform.OS === 'web') {
          const confirmation = window.confirm('Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?');
          if (confirmation) {
            navigation.dispatch(e.data.action);
          }
        } else {
          e.preventDefault();
  
          Alert.alert(
            'Attention',
            'Si vous retournez en arrière, vous allez perdre les données déjà saisies dans ce formulaire. Êtes-vous sûr ?',
            [
              { text: "Annuler", style: 'cancel', onPress: () => {} },
              { text: "OK", style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
            ]
          );
        }
      });
  
      return () => {
        backHandler.remove();
        unsubscribe();
      };
    }, [navigation])
  );
  

  const updateChampsNonRemplis = () => {
    const champs = [];
    if (!selectedVoyageur) champs.push('Voyageur');
    if (!dateDepart.trim()) champs.push('Date de départ');
    if (!dateSortie.trim()) champs.push('Date de sortie');
    if (!statutMarital.trim()) champs.push('Statut marital');
    if (!statutProfessionnel.trim()) champs.push('Statut professionnel (emploi)');
    if (!nomEtablissement.trim()) champs.push("Nom de l'établissement hôtelier ou de l'hébergeant");
    if (!adresseEtablissement.trim()) champs.push("Adresse détaillée de l'établissement hôtelier ou de l'hébergeant");
    if (!telephoneEtablissement.trim()) champs.push("Numéro de téléphone de l'établissement hôtelier ou de l'hébergeant");
    if (!emailEtablissement.trim()) champs.push("Adresse électronique de l'établissement hôtelier ou de l'hébergeant");
    if (!assuranceVisa.trim()) champs.push('Assurance Visa');
  
    setChampsNonRemplis(champs);
  };

  useEffect(() => {
    updateChampsNonRemplis();
  }, [assuranceVisa, dateDepart, dateSortie, statutMarital, statutProfessionnel, nomEtablissement, adresseEtablissement, telephoneEtablissement, emailEtablissement]);

  useEffect(() => {
    setIsButtonEnabled(champsNonRemplis.length === 0);
  }, [champsNonRemplis]);

  useEffect(() => {
    const fetchVoyageurs = async () => {
      const userJson = await AsyncStorage.getItem('@user');
      if (userJson !== null) {
        const user = JSON.parse(userJson);
        const listeVoyageurs = await recupererListeVoyageurs(user.id_utilisateur);
        setVoyageurs(listeVoyageurs);
        if (listeVoyageurs.length === 1) {
          setSelectedVoyageur(listeVoyageurs[0].id.toString());
        }
      }
    };
    fetchVoyageurs().then(() => {});
  }, [demandeId]);

  const selectedVoyageurObj = voyageurs.find(v => v.id.toString() === selectedVoyageur);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const userJson = await AsyncStorage.getItem('@user');
      
      let userId = null;
      if (userJson) {
        const user = JSON.parse(userJson);
        userId = user.id_utilisateur;
      }

      const etatFormulaire = champsNonRemplis.length === 0;

      const data = {
        etatFormulaire: etatFormulaire,
        idUtilisateur: userId,
        idVoyageur: selectedVoyageur,
        titreVisa: title,
        dateDepart: dateDepart,
        dateSortie: dateSortie,
        prixconsulat: prixconsulat,
        prixintervention: prixintervention,
        assuranceVisa: assuranceVisa,
        statutMarital: statutMarital,
        statutProfessionnel: statutProfessionnel,
        nomEtablissement: nomEtablissement,
        adresseEtablissement: adresseEtablissement,
        telephoneEtablissement: telephoneEtablissement,
        emailEtablissement: emailEtablissement,
       
      };

      await validerDemande(data);
      console.log('Demande validée avec succès');

      setIsLoading(false);
      navigation.navigate('Demandes', '');
    } catch (error) {
      console.error('Erreur lors de la validation de la demande:', error);
      setIsLoading(false);
    }
  };

  const formatDate = (input: string) => {
    const numbers = input.replace(/[^\d]/g, '');
    const chars = numbers.split('');
    let formattedInput = '';

    chars.forEach((char, index) => {
      if (index === 2 || index === 4) {
        formattedInput += '/';
      }
      formattedInput += char;
    });

    return formattedInput.slice(0, 10);
  };

  const backgroundColor = champsNonRemplis.length > 0 ? '#ffcccb' : '#ccffcc';

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <KeyboardAwareScrollView style={styles.scrollView}>
        <View style={styles.container}>
        {urlFlag && (
            <View style={styles.flagContainer}>
              <Image source={{ uri: urlFlag }} style={styles.flagImage} />
            </View>
          )}
          <Text style={styles.title}>Formulaire pour {title}</Text>
          <Text style={styles.libelle}>Prix : {prixTotal}€</Text>

          {!isEditMode && (
            <View>
              <Text style={styles.centeredBoldText}>Sélectionner un voyageur</Text>
              <View style={styles.pickerContainer}>
                <RNPickerSelect
                  onValueChange={(value) => setSelectedVoyageur(value)}
                  items={voyageurs.map((voyageur) => ({
                    label: `${voyageur.prenom} ${voyageur.nom}`,
                    value: voyageur.id.toString(),
                  }))}
                  placeholder={{
                    label: 'Sélectionner un voyageur',
                    value: null,
                  }}
                  style={pickerSelectStyles}
                  value={selectedVoyageur}
                  useNativeAndroidPickerStyle={false}
                />
              </View>
            </View>
          )}
          {selectedVoyageurObj && (
            <View style={styles.avatarContainer}>
              <Image
                source={{ uri: selectedVoyageurObj.url || 'https://example.com/default-avatar.png' }}
                style={styles.avatar}
              />
            </View>
          )}
          <View style={styles.inputContainer}>
            <Text>Date de départ de votre voyage :</Text>
            <TextInput
              style={[styles.textInput,]}
              placeholder="Date de départ de votre voyage JJ/MM/AAAA"
              value={dateDepart}
              onChangeText={(text) => setdateDepart(formatDate(text))}
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Date de retour de votre voyage :</Text>
            <TextInput
              style={[styles.textInput,]}
              placeholder="Date de retour de votre voyage JJ/MM/AAAA"
              value={dateSortie}
              onChangeText={(text) => setdateSortie(formatDate(text))}
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Statut marital :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setStatutMarital}
              value={statutMarital}
              placeholder="Votre statut marital"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Statut professionnel (emploi) :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setStatutProfessionnel}
              value={statutProfessionnel}
              placeholder="Votre statut professionnel"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Nom de l'établissement hôtelier ou de l'hébergeant :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setNomEtablissement}
              value={nomEtablissement}
              placeholder="Nom de l'établissement"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Adresse détaillée de l'établissement hôtelier ou de l'hébergeant :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setAdresseEtablissement}
              value={adresseEtablissement}
              placeholder="Adresse détaillée"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Numéro de téléphone de l'établissement hôtelier ou de l'hébergeant :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setTelephoneEtablissement}
              value={telephoneEtablissement}
              placeholder="+966"
            />
          </View>
          <View style={styles.inputContainer}>
            <Text>Adresse électronique de l'établissement hôtelier ou de l'hébergeant :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setEmailEtablissement}
              value={emailEtablissement}
              placeholder="exemple@domaine.com"
              keyboardType="email-address"
            />
          </View>
          <AssuranceVisaComponent
            assuranceVisa={assuranceVisa}
            setAssuranceVisa={setAssuranceVisa}
          />
          <View style={[styles.champsNonRemplisContainer, { backgroundColor }]}>
            {champsNonRemplis.length > 0 ? (
              <>
                <Text>Formulaire incomplet. Champs manquants :</Text>
                {champsNonRemplis.map((champ, index) => (
                  <Text key={index}>{champ}</Text>
                ))}
              </>
            ) : (
              <Text>Formulaire complet</Text>
            )}
          </View>
          {isLoading ? (
            <ActivityIndicator size="large" color="#0000ff" />
          ) : (
            <View style={styles.buttonContainer}>
              <TouchableOpacity
                 onPress={isButtonEnabled ? handleSubmit : disabledPressHandler}
                style={[
                  styles.button,
                  { backgroundColor: champsNonRemplis.length === 0 ? '#3eab36' : '#B0B0B0' }
                ]}
                disabled={champsNonRemplis.length > 0}
              >
                <Text style={styles.buttonText}>Valider ma demande</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </KeyboardAwareScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  flagContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  flagImage: {
    width: 100,
    height: 60,
    resizeMode: 'contain',
  },
  centeredBoldText: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007AFF',
    padding: 15,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 16,
    color: '#fff',
  },
  cameraContainer: {
    position: 'absolute',
    left: 0,
    width: 430,
    height: 750,
  },
  imagePickerButton: {
    backgroundColor: '#4e9af1',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: '80%',
    alignSelf: 'center',
  },
  imagePreview: {
    width: 100,
    height: 100,
    marginTop: 10,
    borderRadius: 5,
    alignSelf: 'center',
  },
  champsNonRemplisContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    margin: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileInput: {
    marginBottom: 20,
    width: '100%',
  },
  scrollView: {
    flex: 1,
    backgroundColor: '#fff',
  },
  inputContainer: {
    marginBottom: 15,
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
  },
  textInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginTop: 5,
    backgroundColor: '#fff',
  },
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f0f0f0',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  libelle: {
    fontSize: 15,
    marginBottom: 20,
  },
  pickerContainer: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ddd',
    overflow: 'hidden',
  },
  picker: {
    width: '100%',
    height: 50,
  },
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ddd',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: '#fff',
  },
  buttonContainer: {
    marginTop: 20,
    width: '100%',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30,
    backgroundColor: 'white',
  },
});

export default FormArabieSaoudite;
